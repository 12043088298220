import React, { useRef} from 'react'
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import { colors } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function AboutUs() {
  
  const containerRef = useRef(null);

  const handleScrollButtonClick1 = (direction) => {
    if (containerRef.current) {
      const container = containerRef.current;
      const scrollOffset =
        direction === "back" ? -container.offsetWidth : container.offsetWidth;

      container.scrollTo({
        left: container.scrollLeft + scrollOffset,
        behavior: "smooth",
      });
    }
  };

    
  return (
    
    <>
    <div id='About' className='bg-white lg:pt-24 !h-auto'>
      <div className="relative max-w-full !h-auto bg-[#fff] overflow-x-hidden lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]" >
        
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5  '>
            <div className={`p-2 lg:p-0 mt-4 lg:w-[460px] xl:w-[570px] transition-transform duration-1000 transform `}>
            <h2 className="text-[24px] text-[#555555] mt-2 font-medium">
            Our Company
            </h2>
            <hr className='w-[160px] border-[#028FC8] border-[1px]'/>
            <br />
              <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                Neonax stands as a prominent global IT solutions provider headquartered in the vibrant city of Dubai. Backed by a dedicated team of seasoned professionals in the software industry, our commitment knows no bounds. <br /> <br />
                Our expertise spans across an array of domains, ranging from cutting-edge Asset Tracking & Management and Perimeter Security Systems to robust ERP Solutions, Mobile Application Development, and Web Development. With an eye for innovation, we also specialize in Branding, SEO, Internet Marketing, and IoT solutions. <br /> <br />
                Neonax isn't just a provider; we are your ally in navigating the digital transformation landscape. Join us on this journey and unlock unprecedented success.
                </p>
            </div>
            <div className='mt-5 lg:mt-0 lg:ml-5'>
            <img src="./assets/aboutus.jpg" alt="Img" className={`mr-100 h-[400px]w-full md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>

    {/* swiper */}
<div className='text-center mt-5' id='whyus'>
  <h1 className='text-[40px] font-light text-[#444444]'>Why <span className="text-[#028FC8] font-medium"> Us</span></h1>
</div>
<div className='mt-10 flex max-w-full lg:ml-[calc((100%-900px)/2)] lg:mr-[calc((100%-900px)/2)] xl:ml-[calc((100%-1280px)/2)] xl:mr-[calc((100%-1280px)/2)] carousel'>
<button onClick={() => handleScrollButtonClick1("back")}>
      <ArrowBackIosIcon className="icon text-[#008FC9] !text-[42px] rounded-full invisible lg:visible"/>
  </button>
  <div className="carousel" ref={containerRef}>
  <div className='carousel-item'>
    <div className="w-[300px] md:w-[387px] h-auto rounded-2xl bg-white mr-5">
      <img src="./assets/longlasting-about.jpg" className="w-[280px] md:w-[387px] h-[258px] rounded-[20px] object-cover" alt='' />
      <hr className="my-5 border-[#444444] w-[280px] md:w-[385px] text-[#444444]" />
          <h1 className="text-[20px] text-gray-700 ">Diverse Solutions</h1>
      <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 py-2">
         From Asset Tracking to ERP and beyond, we cover a wide array of services.
      </p>
    </div>
  </div>
  <div className='carousel-item'>
    <div className="w-[300px] md:w-[387px] h-auto rounded-2xl bg-white mr-5">
      <img src="./assets/revolutionary-about.jpg" className="w-[280px] md:w-[387px] h-[258px] rounded-[20px] object-cover" alt='' />
      <hr className="my-5 border-[#444444] w-[280px] md:w-[385px] text-[#444444]" />
        <h1 className="text-[20px] text-gray-700 ">Innovation</h1>
      <p className="text-base leading-6 text-justify text-gray-700 py-2">
         We're driven by innovation, ensuring your business stays ahead.
      </p>
    </div>
  </div>
  <div className='carousel-item'>
    <div className="w-[300px] md:w-[387px] h-auto rounded-2xl bg-white mr-5">
                <img src="./assets/prices-about.jpg" className="w-[280px] md:w-[387px] h-auto rounded-[20px] object-cover" alt='' />
      <hr className="my-5 border-[#444444] w-[280px] md:w-[385px] text-[#444444]" />
        <h1 className="text-[20px] text-gray-700 ">Value-Centric</h1>
      <p className="text-base leading-6 text-justify text-gray-700 py-2">
                  We focus on delivering solutions that add tangible business value.
      </p>
    </div>
  </div>
  <div className='carousel-item'>
    <div className="w-[300px] md:w-[387px] h-auto rounded-2xl bg-white mr-5">
                <img src="./assets/prototype-about.jpg" className="w-[280px] md:w-[387px] h-[258px] rounded-[20px] object-cover" alt='' />
      <hr className="my-5 border-[#444444] w-[280px] md:w-[385px] text-[#444444]" />
                <h1 className="text-[20px] text-gray-700 ">User-Focused</h1>
      <p className="text-base leading-6 text-justify text-gray-700  py-2">
                  Exceptional user experiences are at the core of what we do.
      </p>
    </div>
  </div>
  <div className='carousel-item'>
    <div className="w-[300px] md:w-[387px] h-auto rounded-2xl bg-white mr-5">
      <img src="./assets/payment-about.jpg" className="w-[280px] md:w-[387px] h-[258px] rounded-[20px]" alt='' />
      <hr className="my-5 border-[#444444] w-[280px] md:w-[385px] text-[#444444]" />
                <h1 className="text-[20px] text-gray-700 ">Future-Oriented</h1>
      <p className="text-base leading-6 text-justify text-gray-700 py-2">
                  Partner with us for success in the new application economy.
      </p>
    </div>
  </div>
  {/* <div className='carousel-item'>
    <div className="w-[300px] md:w-[387px] h-auto rounded-2xl bg-white mr-5">
      <img src="./assets/launch-about.jpg" className="w-[280px] md:w-[387px] h-[258px] rounded-[20px]" alt='' />
      <hr className=" my-5 border-[#444444] w-[280px] md:w-[385px] text-[#444444]" />
      <h1 className="text-[20px] font-semibold text-gray-700 ">Post-Launch Provisions</h1>
      <p className="text-base leading-6 text-justify text-gray-700 py-2">
      Our perception is that with each project launch , the story has only just begun. We have principled ourselves to ensure that the customer feels happy for the choice he has made.
      </p>
    </div>
  </div> */}
 
  </div>
    <button onClick={() => handleScrollButtonClick1("forward")}>
    <ArrowForwardIosIcon className="icon text-[#008FC9] !text-[42px] rounded-full invisible lg:visible"/>
  </button>
</div>

{/* banner */}
<div className="relative max-w-full h-auto bg-[#fff] mt-10 overflow-x-hidden" >
  
    <img src="./assets/aboutusleft2.png" alt="" className={`w-full h-auto' transition-transform duration-500 object-contain transform $`} />
             
    <div className={`absolute top-0 md:top-14 lg:top-28 xl:32 w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] transition-transform duration-500 transform `} >
        <p className='mx-5 md:mx-0 text-[24px] md:text-[48px] text-white'>IT MAKES SENSE TO WORK WITH US</p>
    </div>
</div>


{/* team */}
        <div className='text-center mt-5' id='team'>
        <h1 className='text-[40px] font-light text-[#444444]'>MEET OUR <span className="text-[#028FC8] font-medium">TEAM</span></h1>
      </div>
    <div className='mx-5 mt-5 min-h-[135vh] md:min-h-[65vh] bg-white block md:flex justify-center md:gap-3 min-[375px]:ml-[50px] min-[425px]:ml-[75px] md:ml-20 md:mx-20 lg:mx-0 lg:ml-[calc((100%-1000px)/2)] lg:mr-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] xl:mr-[calc((100%-1200px)/2)]'>
        <div className='block'>
        <div className="mt-5 md:mt-0 w-[285px] lg:w-[240px] xl:w-[285px] h-[390px] bg-white border-2 rounded-[20px] p-3">
        <figure className="px-10 pt-10 rounded-full ">
          <img src="https://img.myloview.com/stickers/default-avatar-profile-icon-vector-unknown-social-media-user-photo-400-209987478.jpg" alt="" className="rounded-full" />
        </figure>
        <div className="md:text-left mx-5 mt-5 text-center">
          <h2 className="text-[16px] font-medium text-[#444444]">Mohammed Ahmed <br/> Mohammed Rashid AlMheiri
</h2>
          <p className="text-[16px] text-[#444444]">Director</p>
        </div>
        <div className="mx-5 mt-[10px] text-center md:text-left">
          <a href="https://www.linkedin.com/" target="_blank"><LinkedInIcon className='text-[#0E76A8]' style={{ fontSize: '35px'}} /></a>
          {/* <a href="https://www.facebook.com/" target="_blank"><FacebookIcon className='text-[#0E76A8]' style={{ fontSize: '35px' }} /></a> */}
          </div>
      </div>
        </div>
        <div className='block'>
        <div className="mt-5 md:mt-0 w-[285px] lg:w-[240px] xl:w-[285px] h-[388px] bg-white border-2 rounded-[20px] p-3">
        <figure className="px-10 pt-10 rounded-full ">
          <img src="./assets/anchu.jpg" alt="" className="rounded-full" />
        </figure>
              <div className="text-center md:text-left mx-5 mt-10">
          <h2 className="text-[16px] font-medium text-[#444444]">Anchunath Reghunathan</h2>
          <p className="text-[16px] text-[#444444]">Director</p>
        </div>
              <div className="mx-5 mt-[10px] text-center md:text-left">
          <a href="https://www.linkedin.com/in/anchunath-r-36a84413" target="_blank"><LinkedInIcon className='text-[#0E76A8]' style={{ fontSize: '35px'}} /></a>
          {/* <a href="https://www.facebook.com/" target="_blank"><FacebookIcon className='text-[#0E76A8]' style={{ fontSize: '35px' }} /></a> */}
          </div>
      </div>
        </div>
        {/* <div className='block'>
        <div className="mt-5 md:mt-0 w-[285px] lg:w-[240px] xl:w-[285px] h-[388px] bg-white border-2 rounded-[20px]">
        <figure className="px-10 pt-10 rounded-full ">
          <img src="./assets/profile01.png" alt="" className="rounded-full" />
        </figure>
        <div className="text-left mx-5 mt-5">
          <h2 className="text-[16px] font-medium text-[#444444]">Puthen Purayil Muhammed Yunus</h2>
          <p className="text-[16px] text-[#444444]">Director</p>
        </div>
        <div className="mx-5 mt-[10px]">
          <a href="https://www.linkedin.com/"><LinkedInIcon className='text-[#0E76A8]' style={{ fontSize: '35px'}} /></a>
          <a href="https://www.facebook.com/"><FacebookIcon className='text-[#0E76A8]' style={{ fontSize: '35px' }} /></a>
          </div>
      </div>
        </div> */}
        {/* <div className='block'>
        <div className="mt-5 md:mt-0 w-[285px] lg:w-[240px] xl:w-[285px] h-[388px] bg-white border-2 rounded-[20px]">
        <figure className="px-10 pt-10 rounded-full ">
          <img src="./assets/profile01.png" alt="" className="rounded-full" />
        </figure>
        <div className="text-left mx-5 mt-10">
          <h2 className="text-[16px] font-medium text-[#444444]">Shijith Mohan</h2>
          <p className="text-[16px] text-[#444444]">Director</p>
        </div>
        <div className="mx-5 mt-[10px]">
          <a href="https://www.linkedin.com/"><LinkedInIcon className='text-[#0E76A8]' style={{ fontSize: '35px'}} /></a>
          <a href="https://www.facebook.com/"><FacebookIcon className='text-[#0E76A8]' style={{ fontSize: '35px' }} /></a>
          </div>
      </div>
        </div> */}
        
      </div>

    </div>
    </>
  )
}
