import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  // lap
  // const [isProductOpen, setProductOpen] = useState(false);
  // const [isSolutionOpen, setSolutionOpen] = useState(false);

  // mob
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  // product mob view
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownOpen && !event.target.closest('#navbar-dropdown')) {
        setDropdownOpen(true);

      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  // end

  // solu start mobile view
  const toggleDropdown1 = () => {
    setDropdownOpen1(!isDropdownOpen1);
  };

  useEffect(() => {
    const handleClickOutside1 = (event) => {
      if (isDropdownOpen1 && !event.target.closest('#navbar-dropdown')) {
        setDropdownOpen1(true);

      }
    };

    document.addEventListener('click', handleClickOutside1);

    return () => {
      document.removeEventListener('click', handleClickOutside1);
    };
  }, [isDropdownOpen1]);

  // end

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  // 
  const [show1, setShow1] = useState(false);


  // const handleCheck = () => {
  //   setShow(!show);
  //   console.log("checks", show);
  // };
  // pro
  const handleCheck1 = () => {
    setShow1(!show1);
    console.log("checks", show1);
  };


  const [activeSection, setActiveSection] = useState(null);

  const handleCheck = (section) => {
    if (activeSection === section) {
      setActiveSection(null); // Close the active section if clicked again
    } else {
      setActiveSection(section); // Open the clicked section
    }
  };


  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };


  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const [isSubMenuHovered, setIsSubMenuHovered] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);

  const handleSubMenuHover = () => {
    setIsSubMenuOpen(true);
  };

  const handleMenuHover = (isHovered) => {
    setIsMenuHovered(isHovered);
    if (!isHovered && !isSubMenuHovered) {
      setIsSubMenuOpen(false);
    }
  };

  const closeNavbar = () => {
    setShowSideMenu(false);
  };


  const [isSubMenuOpen1, setIsSubMenuOpen1] = useState(false);
  const [isMenuHovered1, setIsMenuHovered1] = useState(false);
  const [isSubMenuHovered1, setIsSubMenuHovered1] = useState(false);
  const [showSideMenu1, setShowSideMenu1] = useState(false);

  const handleSubMenuHover1 = () => {
    setIsSubMenuOpen1(true);
  };

  const handleMenuHover1 = (isHovered1) => {
    setIsMenuHovered1(isHovered1);
    if (!isHovered1 && !isSubMenuHovered1) {
      setIsSubMenuOpen1(false);
    }
  };

  const closeNavbar1 = () => {
    setShowSideMenu1(false);
  };


  return (
    <>
      <nav class="!bg-white hidden lg:block  md:hidden fixed z-10 w-full  border-gray-200 ">
        <div class=" flex flex-wrap items-center justify-between p-4  " onClick={() => handleMenuHover(false)}>
          <li class="flex items-center lg:ml-[calc((100%-950px)/2)] xl:ml-[calc((100%-1200px)/2)]">
            <Link to='/' onMouseEnter={() => handleMenuHover(false)}><img src="./assets/logo.png" class="h-[66px] w-[200px] " alt=" Logo" onMouseEnter={() => handleMenuHover1(false)} /></Link>
          </li>
          <button data-collapse-toggle="navbar-dropdown" type="button" class="inline-flex items-center md:hidden lg:hidden  text-sm text-gray-500 rounded-lg mr-5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
          </button>
          <div class=" hidden w-full lg:block lg:w-auto lg:mr-[calc((100%-950px)/2)] xl:mr-[calc((100%-1200px)/2)]" id="navbar-dropdown">
            <ul class="flex flex-col font-medium  md:p-0  border border-gray-100 rounded-lg bg-gray-50 md:flex-row lg:space-x-5   md:mt-0 md:border-0 md:bg-white ">
              <Link to='/' onMouseEnter={() => handleMenuHover(false)}>
                <li
                  class="block py-2 pl-3 pr-4 text-[#444444] rounded md:bg-transparent md:text-[#444444] md:p-0 md:dark:text-[#444444]  md:dark:bg-transparent md:hover:text-[#008FC9] md:dark:hover:text-[#008FC9]" aria-current="page" onClick={() => {
                    setActiveSection(activeSection === 'section' ? null : ''); // Toggle section2
                  }} onMouseEnter={() => handleMenuHover1(false)}>Home
                </li></Link>

              <Link to='/aboutus' onMouseEnter={() => handleMenuHover(false)}>
                <li class="flex py-2 pl-3 pr-4 text-[#444444] rounded md:bg-transparent md:text-[#444444] md:p-0 md:dark:text-[#444444]  md:dark:bg-transparent md:hover:text-[#008FC9] md:dark:hover:text-[#008FC9]" aria-current="page" onClick={() => {
                  setActiveSection(activeSection === 'section' ? null : ''); // Toggle section2
                }} onMouseEnter={() => handleMenuHover1(false)}>About Us
                </li></Link>
              <label
                tabIndex={0} onMouseEnter={() => handleMenuHover1(false)}
                className=" cursor-pointer flex md:hover:text-[#008FC9]"

              >
                <div className='hover:text-[#008FC9] flex' onMouseEnter={() => handleMenuHover(true)} onClick={() => handleMenuHover(false)}>
                  <li class="block py-2 pl-3 pr-4 text-[#444444] rounded md:bg-transparent md:text-[#444444] md:p-0 md:dark:text-[#444444]  md:dark:bg-transparent md:hover:text-[#008FC9] md:dark:hover:text-[#008FC9]" onMouseEnter={handleSubMenuHover} >
                    Products
                  </li><svg onMouseEnter={handleSubMenuHover} className="w-5 h-5 ml-1 mt-1 text-gray-900" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></div>



                {isSubMenuOpen && (
                  <ul
                    tabIndex={0} onClick={() => handleMenuHover(false)}
                    className={

                      " dropdown-content mt-16 !overflow-hidden bg-white border-t-4 !rounded-none justify-between w-full z-40 min-h-[60vh] fixed top-8 left-0 p-5"
                    }
                  >
                    <li className="text-xs xl:text-sm grid !overflow-hidden grid-cols-3 z-40 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]" onClick={() => {
                      closeNavbar();
                      setIsSubMenuOpen(false); // Close the submenu
                    }}>
                      <li className=' relative mt-2 '>
                        <Link to='/product01' onClick={handleCheck1}><img src='./assets/prtimg01.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] text-[14px] lg:text-[16px] xl:text-[16px] " >GATEWAY - DCU -  IoT Hub</h5></Link>
                      </li>
                      <li className=' relative mt-2 '>
                        <Link to='/product02' onClick={handleCheck1}><img src='./assets/prtimg02.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >BLE Based temperature and <br /> humidity sensor </h5></Link>
                      </li>
                      <li className=' relative mt-2 '>
                        <Link to='/product03' onClick={handleCheck1}><img src='./assets/prtimg03.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >6LOWPAN RF Mesh-15dB & 28dB</h5></Link>
                      </li>
                      <li className=' relative mt-10 '>
                        <Link to='/product04' onClick={handleCheck1}><img src='./assets/prtimg04.png' className="img-fluid b-r-[20px]  lg:w-[320px]   xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Bluetooth / Wifi based IoT App</h5></Link>
                      </li>
                      <li className=' relative mt-10 ' >

                        <Link to='/product05' onClick={handleCheck1}><img src='./assets/prtimg05.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >RFID Handheld scanners</h5></Link>

                      </li>
                      <li className=' relative mt-10 '>

                        <Link to='/product06' onClick={handleCheck1}><img src='./assets/prtimg06.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[28%] font-[16px]" >LoRaWAN</h5></Link>


                      </li>
                      <li className=' relative mt-10 ' >

                        <Link to='/product07' onClick={handleCheck1}><img src='./assets/prtimg06.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[28%] font-[16px]" >EV Charging</h5></Link>

                      </li>
                      <li className=' relative mt-10 ' >

                        <Link to='/product09' onClick={handleCheck1}><img src='./assets/prtimg02.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[28%] font-[16px]" >BLE Beacons </h5></Link>

                      </li>
                      {/* <li className=' relative mt-10 ' >
                      
                    <Link to='/product08' onClick={handleCheck1}><img src='./assets/Group 105.png' className="img-fluid shadow-inner b-r-[20px] rounded-2xl lg:w-[320px] xl:w-[387px] lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[28%] font-[16px]" >HRMS - TiaTech</h5></Link>

                    </li> */}
                    </li>
                  </ul>
                )}
              </label>

              {/* solu */}

              <label
                tabIndex={0} onMouseEnter={() => handleMenuHover(false)}
                className=" cursor-pointer flex md:hover:text-[#008FC9]"

              >
                <div className='flex hover:text-[#008FC9]' onMouseEnter={() => handleMenuHover1(true)} onClick={() => handleMenuHover1(false)}>
                  <li class="block py-2 pl-3 pr-4 text-[#444444] rounded md:bg-transparent md:text-[#444444] md:p-0 md:dark:text-[#444444]  md:dark:bg-transparent md:hover:text-[#008FC9] md:dark:hover:text-[#008FC9]" onMouseEnter={handleSubMenuHover1}>
                    Solution
                  </li><svg onMouseEnter={handleSubMenuHover1} className="w-5 h-5 ml-1 mt-1 text-gray-900" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></div>


                {isSubMenuOpen1 && (
                  <ul
                    tabIndex={0} onClick={() => handleMenuHover1(false)}
                    className={
                      " dropdown-content mt-16 bg-white border-t-4 !rounded-none justify-between w-full z-40 min-h-auto absolute top-8 -left-0 p-5 overflow-y-auto max-h-[80vh]"
                    }
                  >
                    <li className="text-xs xl:text-sm grid grid-cols-3 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]" onClick={() => {
                      closeNavbar1();
                      setIsSubMenuOpen1(false); // Close the submenu
                    }}>
                      <li className=' relative mt-5  '>
                        <Link to='/Solution01' onClick={handleCheck}><img src='./assets/solimg1.png' className="img-fluid b-r-[20px] lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute  top-[45%] left-[22%] font-[16px]" >Smart Tracking</h5></Link>
                      </li>

                      <li className=' relative mt-5  '>
                        <Link to='/Solution02' onClick={handleCheck}><img src='./assets/solimg2.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Smart Parking System</h5></Link>
                      </li>

                      <li className=' relative mt-5  '>
                        <Link to='/Solution03' onClick={handleCheck}><img src='./assets/solimg3.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Smart Electricity Meter</h5></Link>
                      </li>

                      <li className=' relative mt-10  '>
                        <Link to='/Solution04' onClick={handleCheck}><img src='./assets/solimg4.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Smart Gas and water meter</h5></Link>
                      </li>

                      <li className=' relative mt-10'>
                        <Link to='/Solution05' onClick={handleCheck}><img src='./assets/solimg5.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Smart Environmental Sensor</h5></Link>
                      </li>
                      <li className=' relative mt-10'>
                        <Link to='/Solution06' onClick={handleCheck}><img src='./assets/solimg6.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Smart Street Light</h5></Link>
                      </li>

                      <li className=' relative mt-10'>
                        <Link to='/Solution07' onClick={handleCheck}><img src='./assets/solimg7.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Smart Trash Bin</h5></Link>
                      </li>

                      <li className=' relative mt-10'>
                        <Link to='/Solution08' onClick={handleCheck}><img src='./assets/solimg8.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Software Solution</h5></Link>
                      </li>
                      <li className=' relative mt-10'>
                        <Link to='/Solution09' onClick={handleCheck}><img src='./assets/solimg9.png' className="img-fluid b-r-[20px]  lg:w-[320px] xl:w-[387px]   lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Smart Perimeter Security</h5></Link>
                      </li>
                      <li className=' relative mt-10'>
                        <Link to='/Solution10' onClick={handleCheck}>
                          <img src='./assets/Group 103.png' className="img-fluid b-r-[20px] object-cover lg:w-[320px] xl:w-[387px] rounded-2xl  lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >Smart MailBox</h5></Link>
                      </li>
                      <li className=' relative mt-10'>
                        <Link to='/Solution11' onClick={handleCheck}>
                          <img src='./assets/Group 104.png' className="img-fluid b-r-[20px] object-cover lg:w-[320px] xl:w-[387px] rounded-2xl  lg:h-[158px] xl:h-[158px] h-[100px] w-[387px]" alt=''></img>
                          <h5 class="text-white absolute top-[45%] left-[22%] font-[16px]" >AI solutions and AI based <br /> IoT computing</h5></Link>
                      </li>
                    </li>
                  </ul>
                )}
              </label>

              <Link to='/Careers' onMouseEnter={() => handleMenuHover(false)}>
                <li class="block py-2 pl-3 pr-4 text-[#444444] rounded md:bg-transparent md:text-[#444444] md:p-0 md:dark:text-[#444444]  md:dark:bg-transparent md:hover:text-[#008FC9] md:dark:hover:text-[#008FC9]" onClick={() => {
                  setActiveSection(activeSection === 'section' ? null : ''); // Toggle section2
                }} onMouseEnter={() => handleMenuHover1(false)}>Careers</li>
              </Link>
              <Link to='/Contact' onMouseEnter={() => handleMenuHover(false)}>
                <li class="block py-2 pl-3 pr-4 text-[#444444] rounded md:bg-transparent md:text-[#444444] md:p-0 md:dark:text-[#444444]  md:dark:bg-transparent md:hover:text-[#008FC9] md:dark:hover:text-[#008FC9]" onClick={() => {
                  setActiveSection(activeSection === 'section' ? null : ''); // Toggle section2
                }} onMouseEnter={() => handleMenuHover1(false)}>Contact Us
                </li></Link>
            </ul>
          </div>
        </div >
      </nav >


      {/* mobile  */}
      <nav className=" !bg-white border w-full h-full lg:hidden  border-gray-200">
        <div className="flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to='/'><li className="flex items-center">
            <img src="./assets/logo.png" className="h-8 mr-3" alt="Logo" />
          </li></Link>
          <button
            type="button"
            className={`inline-flex items-center text-sm lg:hidden text-gray-500 rounded-lg mr-5 hover:bg-gray-100    dark:text-gray-400  ${isMobileMenuOpen ? 'open' : ''
              }`}
            aria-controls="navbar-menu"
            aria-expanded={isMobileMenuOpen}
            onClick={toggleMobileMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div
            className={`${isMobileMenuOpen ? 'block' : 'hidden'
              } w-full absolute left-0 lg:hidden z-20 top-10 md:top-7`}
            id="navbar-menu"
          >
            <ul className=" font-medium w-full p-4 mt-4 md:h-full md:mt-10 bg-white md:flex-row md:space-x-8  border-b-4 ">
              <Link to='/' onClick={closeMobileMenu}>
                <li
                  className="block py-2 pl-3 pr-4  text-black  rounded md:bg-transparent hover:bg-gray-100 md:hover:bg-gray-100"
                  aria-current="page"
                >
                  Home
                </li>
              </Link>

              <Link to='/aboutus' onClick={closeMobileMenu}>
                <li
                  className="block py-2 pl-3 pr-4  text-black  rounded hover:bg-gray-100 md:hover:bg-gray-100 md:border-0 md:hover:text-blue-700 "
                >
                  About Us
                </li>
              </Link>

              <li className="">
                <button
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar"
                  className="flex items-center justify-between w-full md:-ml-8 py-5 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent   "
                  onClick={toggleDropdown}
                >
                  Products
                  <span class="material-symbols-outlined">
                    arrow_drop_down
                  </span>
                </button>
                <div
                  id="dropdownNavbar"
                  className={`${isDropdownOpen ? 'block' : 'hidden'
                    }  w-full lg:hidden z-20 `}
                >
                  <ul
                    className=" text-sm text-gray-800 dark:text-gray-800"
                    aria-labelledby="dropdownLargeButton"
                  >

                    <Link to='/product01' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        GATEWAY - DCU -  IoT Hub
                      </li>
                    </Link>
                    <Link to='/product02' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        BLE Based temperature and humidity sensor
                      </li>
                    </Link>
                    <Link to='/product03' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        6LOWPAN RF Mesh-15dB & 28dB
                      </li>
                    </Link>
                    <Link to='/product04' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Bluetooth / Wifi based IoT App
                      </li>
                    </Link>
                    <Link to='/product05' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        RFID Handheld scanners
                      </li>
                    </Link>
                    <Link to='/product06' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        LoRaWAN
                      </li>
                    </Link>
                    <Link to='/product07' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        EV Charging
                      </li>
                    </Link>
                    <Link to='/product09' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        BLE Beacons
                      </li>
                    </Link>
                    {/* <Link to='/product08' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        HRMS - TiaTech
                      </li>
                    </Link> */}
                  </ul>

                </div>
              </li>
              {/* solu */}
              <li className="relative">
                <button
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar1"
                  className="flex items-center justify-between w-full md:-ml-8 py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent    "
                  onClick={toggleDropdown1}
                >
                  Solutions
                  
                  <span class="material-symbols-outlined">
                    arrow_drop_down
                  </span>
                </button>
                <div
                  id="dropdownNavbar1"
                  className={`${isDropdownOpen1 ? 'block' : 'hidden'

                    }  w-full lg:hidden z-20 `}
                >
                  <ul
                    className="py-2 text-sm text-gray-800 dark:text-gray-800"
                    aria-labelledby="dropdownLargeButton"
                  >
                    <Link to='/Solution01' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Smart Tracking
                      </li>
                    </Link>
                    <Link to='/Solution02' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Smart Parking System
                      </li>
                    </Link>
                    <Link to='/Solution03' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Smart Electricity Meter
                      </li>
                    </Link>
                    <Link to='/Solution04' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Smart Gas and Water Meter
                      </li>
                    </Link>
                    <Link to='/Solution05' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Smart Environmental Sensor
                      </li>
                    </Link>
                    <Link to='/Solution06' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Smart Street Light
                      </li>
                    </Link>
                    <Link to='/Solution07' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Smart Trash Bin
                      </li>
                    </Link>
                    <Link to='/Solution08' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Software Solution
                      </li>
                    </Link>
                    <Link to='/Solution09' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Smart Perimeter Security
                      </li>
                    </Link>
                    <Link to='/Solution10' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Smart MailBox
                      </li>
                    </Link>
                    <Link to='/Solution11' onClick={closeMobileMenu}>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        AI solutions and AI based IoT computing
                      </li>
                    </Link>
                  </ul>

                </div>
              </li>

              <Link to='/careers' onClick={closeMobileMenu}>
                <li
                  className="block py-2 pl-3 pr-4 text-black  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 "
                >
                  Careers
                </li>
              </Link>
              <Link to='/Contact' onClick={closeMobileMenu}>
                <li
                  className="block py-2 pl-3 pr-4 text-black  rounded hover:bg-gray-100 md:hover:bg-transparent  md:hover:text-blue-700   "
                >
                  Contact Us
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </>

  );
}

export default Navbar;
