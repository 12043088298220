import React from 'react'

export default function Solution03() {
  
  return (
    <>
       <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Smart Electricity Meter</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#FFF] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Smart Electricity Meter Solution
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
            Smart meters, part of an advanced metering infrastructure are the single largest source of data. The success of smart metering depends up on strong and reliable communication technology.
The reliable connectivity in last mile scenarios like individual energy meter in the network connecting to the data concentrator in turn to the meter data management systems (MDMS).   
            </p>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">There are emerging low power WAN technologies such as Wi-SUN-in licensed band, that can provide alternative long-range connectivity option for realizing IoT networks. Also, option of RF and PLC include in same application.Further, we highlight how emerging communication technologies will help in building a reliable, low cost, low power, long range, last mile technology for smart energy metering solutions.

</p>
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/asset_elecricity.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px]  transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    </div>  
    </>
  )
}
