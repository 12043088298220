import React from 'react'

export default function Solution10() {

    

    return (
        <>
            <div className='bg-white w-full pb-10 overflow-x-hidden'>
                <div className="relative max-w-full lg:pt-28">
                    <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
                    <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                        <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Smart MailBox</h1>
                    </div>
                </div>
                <div className="relative max-w-full h-auto bg-[#fff] py-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                    <h2 className="text-[24px] text-[#444444] font-medium mx-5 md:mx-0 mb-2">
                        Smart MailBox
                    </h2>
                    <div className='flex flex-col justify-center items-center mx-5 md:mx-0'  >
                        <div className={`p-2 lg:p-0 lg:w-full transition-transform duration-500 transform `}>
                            <img src="./assets/mailbox01.png" alt="" className='md:h-[400px] md:w-full lg:w-full object-contain' />
                        </div>
                        <div className=''>
                            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
                                Features: 
                            </h2>
                            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-5">
                                ●	Primary Material: Steel (Secure & Weatherproof frame made of Structural Steel) <br />
                                ●	Rust Resistant: Yes <br />
                                ●	Fade Resistant: Yes <br />
                                ●	Anti-theft parcel slot, stops hands from entering the unit <br />
                                ●	Vibration sensor and Tamper detection circuit with Alarm and Notification to user smartphone <br />
                                ●	Smart electronic lock, will open with security key from smart phone or using manual key <br />
                                ●	Full electronics with high-speed microprocessor with WIFI or GSM-4G Module communication module for video streaming and recording <br />
                                ●	High Resolution Camera with Night vision and Mic for live stream and record for 30 days <br />
                                ●	Motion Sensor to detect presences of human and start the recording and also notification to user mobile <br />
                                ●	120V power supply for the operation and also battery backup option <br />
                                ●	Sensor to detect the presence of package (Delivered, Expected and Create Pickup)

                            </p>

                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}
