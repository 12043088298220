import React from 'react'

export default function Solution05() {
 

  return (
    <>
       <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Smart Environmental Sensor</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Smart Environmental Sensor
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
            Air pollution is a disaster that can indirectly interfere with human health.
One of the types of pollution that threatens public health is the increase of CO, NO2 & SO2 level in the air.
With the increasing level of air pollution in the city, it requires a device that can monitor air pollution in a real time

            </p>
            <br />
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Sensors
            </h2>
            <br />
            <ul className="font-normal list-disc pl-5 space-y-1 text-base leading-7 text-justify text-[#444444]">
                <li>Heterogeneous Gas Sensor</li>
                <li>Humidity Sensor</li>
                <li>Temperatue Sensor</li>
                <li>Infra Red Sensor</li>
                <li>Dust Sensor</li>
                <li>UV Sensor</li>

            </ul>
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/smart_environment.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px]  transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    </div> 
    </>
  )
}
