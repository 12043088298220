import React from 'react'

export default function Product07() {



  return (
    <>
      <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>EV Charger</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            TO OUR TEAM, PARTNERS & CUSTOMERS
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
            We are working on creating the largest smart charge grid
            setup in India, we also support and design the technology for
            installation of the system to ensure minimum resource
            utilization and ensures maximum value-added benefits for all
            parties. We have established clear steps to achieve our
            objective of planning on setting up 1 million chargers across
            the country and along with generating and developing
            according to latest technological trends.
            We just the love the world we live in! <br/>
            <span className='font-medium'>Power to People</span>
            </p> 
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/evcharger.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 md:mt-10'>
            <img src="./assets/evcharg-bg.png" alt="Img" className={` h-[400px] md:w-full lg:w-[460px] xl:w-[510px] transition-transform duration-500 transform object-contain `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
            <div className={`text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
            <h1 className='text-[24px] text-[#444444] font-medium mt-10'>Smarter charging</h1> <br/>
            <h1 className='text-[24px] text-[#444444] font-medium mt-10'>Overview</h1>
                <ul className="font-normal list-disc pl-5 space-y-1 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8" >
            <li>From 3.7 kW to 22 kW</li>
             <li>Robust, durable, low maintenance</li>
             <li>Compatible with Ambi-charge system</li>
             <li>Tracks and manages charging sessions</li>
             <li>Distributes power efficiently with Load Balancing</li>
             <li>Integrated with multiple software providers for customer flexibility</li>
            </ul>
            </div>
            </div>
        </div>
    </div>
    </div> 
    </>
  )
}
