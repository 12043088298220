import React from 'react'

export default function Product09() {

    

    return (
        <>
            <div className='bg-white w-full pb-10 overflow-x-hidden'>
                <div className="relative max-w-full lg:pt-28">
                    <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
                    <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                        <h1 className='mx-5 md:mx-0 text-[24px] text-white'>BLE Beacons</h1>
                    </div>
                </div>
                <div className="relative max-w-full h-auto bg-[#fff] py-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                    <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'  >
                        <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
                            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
                                Why BLE Beacon?
                            </h2>
                            <br />
                            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                                Bluetooth Low Energy (BLE) Beacons have emerged as a transformative technology with a myriad of benefits across industries. Here's why you should consider integrating BLE Beacons into your solutions:
                            </p>
                            <br />
                            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                                Precision Location Services: BLE Beacons enable highly accurate indoor positioning and location-based services, facilitating asset tracking, wayfinding, and personalized user experiences. <br /> <br />
                                ●	Efficient Asset Management: Employ BLE Beacons to monitor and manage assets in real-time, optimizing workflows and enhancing operational efficiency. <br />
                                ●	Enhanced User Engagement: Deliver customized content and experiences based on users' physical location, fostering deeper engagement and satisfaction. <br />
                                ●	IoT Integration: BLE Beacons seamlessly integrate with IoT ecosystems, enabling data-driven decision-making and automation. <br />
                                ●	Cost-Effective: BLE technology consumes minimal energy, resulting in longer battery life and cost-effective deployments. <br />
                                ●	Scalability: Easily scale your Beacon network to cover larger areas without compromising performance. <br />
                                ●	Versatile Applications: From retail and hospitality to healthcare and logistics, BLE Beacons find applications across diverse sectors. <br />
                                
                            </p>
                        </div>
                        <div className='mt-5 lg:mt-36'>
                            <img src="./assets/Picture3.png" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[550px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform object-contain `} />
                        </div>
                    </div>
                </div>
                <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                    <img src="./assets/Picture4.png" alt="Img" className={`w-full h-auto md:h-[400px] transition-transform duration-500 transform `} />
                </div>
                
                
            </div>
        </>
    )
}
