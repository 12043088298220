import React from 'react'

export default function Product11() {


    return (
        <>
            <div className='bg-white w-full pb-10 overflow-x-hidden'>
                <div className="relative max-w-full lg:pt-28">
                    <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
                    <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                        <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Embedded System Services</h1>
                    </div>
                </div>
                <div className="relative max-w-full h-auto bg-[#FFF] py-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                    <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
                        <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-10">
                                Design and develop robust, customised software solutions tailored to run efficiently on embedded hardware platforms.
                            </p>
                            <br />
                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Hardware Design & Prototyping:
                            </h2>

                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Create high-performance hardware solutions, from schematic design to prototyping, catering to diverse industry requirements.
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                IoT Device Development:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Engineer IoT devices and solutions that connect seamlessly, collect data, and enable intelligent decision-making for your business.
                            </p>

                        </div>
                        <div className='mt-5 lg:mt-10'>
                            <img src="./assets/embedded-system.webp" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[550px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform object-contain`} />
                        </div>
                    </div>
                </div>

                <div className="relative max-w-full h-auto bg-[#FFF] py-5 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                    <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
                        <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Firmware Development:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Craft firmware that optimises device functionality, ensuring stability, security, and smooth operation.
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Embedded System Integration:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Seamlessly integrate embedded systems into your existing infrastructure for enhanced performance and functionality.
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Testing & Validation:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Conduct rigorous testing and validation to ensure reliability, security, and compliance of embedded systems.
                            </p>
                        </div>
                        <div className='mt-5 lg:mt-5'>
                            <img src="./assets/Embedded-systems2.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[550px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform object-contain `} />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
