import React from 'react'

export default function Product06() {
 
  return (
    <>
      <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
            <h1 className='mx-5 md:mx-0 text-[24px] text-white'>LoRaWAN</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
                LoRaWAN Sensor
            </h2>
            <br />
              <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                ●	Extended Range: LoRaWAN boasts impressive coverage, enabling communication over vast distances, even in challenging terrains. <br />
                ●	Low Power Consumption: Devices can operate on minimal energy, leading to longer battery life and reduced operational costs. <br />
                ●	Efficient Data Transmission: Ideal for small data packets, LoRaWAN optimizes energy usage while catering to diverse IoT applications. <br />
                ●	Scalable Networks: LoRaWAN supports a multitude of devices within a single network, making it perfect for large-scale implementations. <br />
                ●	Cost-Effective Deployment: With reduced infrastructure needs, LoRaWAN offers an economical choice for widespread adoption. <br />
                ●	Versatile Solutions: From agriculture to smart cities, LoRaWAN's adaptability suits various sectors and applications. <br />
                ●	Robust Security: Implementing encryption and authentication, LoRaWAN ensures data security across transmissions. <br />
                ●	Open Architecture: As an open standard, LoRaWAN nurtures a thriving ecosystem of devices, gateways, and software.

            </p> 
            </div>
            <div className='mt-5 lg:mt-10 lg:ml-20'>
              <img src="./assets/Ruggadised-gateway4.png" alt="Img" className={`mr-100 h-[400px] object-contain md:w-full lg:w-[460px] xl:w-[400px] md:h-[406px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5'>
            <img src="./assets/semi-industrial-lorawan-gateway.jpg" alt="Img" className={` h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[300px] object-contain transition-transform duration-500 transform `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
            <div className={`font-normal text-base leading-6 text-justify text-[#444444] mt-10 transition-transform duration-500 transform `}>
                <img src="./assets/water_quality.webp" alt="" className='h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[300px] object-contain'/>
            </div>
            </div>
        </div>
    </div>
    </div> 
    </>
  )
}
