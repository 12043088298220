import React from 'react'
export default function Solution07() {
  
  return (
    <>
       <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Smart Trash Bin</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Smart Trash Bin
            </h2>
            <br /> 
                          <ul className="font-normal list-disc pl-5 space-y-2 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8">
                <li>LoRa-based LPWAN vehicle to vehicle communication system for smart dustbin.</li>
                <li>The vehicle-to-vehicle communication system consists of a LoRa gateway, a remote diagnostic system, sensors for monitoring the garbage quantity and a cloud platform.</li>
                <li>When bin is filled with garbage, then the sensors which keep track on the garbage level will sense the overflow of the bin.</li>

                <li>Once the sensor detects that the bin is full and needs to cleared it notifies.</li>
                <li>The LoRa gateway transmits the information about the garbage bin data to other nearby vehicle which is a smart dustbin by recording this information in the cloud platform .</li>
                <li>The dustbin can communicate with another dustbin.</li>

            </ul>
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/smart_trash.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    </div> 
    </>
  )
}