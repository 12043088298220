import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from './component/About';
import Home from './component/Home';
import Careers from './component/Careers';
import Contact from './component/Contact';
import Footer from './component/Footer'
import Navbar from './component/Navbar';
import Product01 from './component/Products/Product01';
import Product02 from './component/Products/Product02';
import Product03 from './component/Products/Product03';
import Product04 from './component/Products/Product04';
import Product05 from './component/Products/Product05';
import Product06 from './component/Products/Product06';
import Solution01 from './component/Solution/Solution01';
import Solution02 from './component/Solution/Solution02';
import Solution03 from './component/Solution/Solution03';
import Solution04 from './component/Solution/Solution04';
import Solution05 from './component/Solution/Solution05';
import Solution06 from './component/Solution/Solution06';
import Solution07 from './component/Solution/Solution07';
import Solution08 from './component/Solution/Solution08';
import Solution09 from './component/Solution/Solution09';
import GotoTop from './component/gototop';
import Product07 from './component/Products/Product07';
import product08 from './component/Products/Product08';
import Privacy from './component/privacy';
import Product09 from './component/Products/Product09';
import Solution10 from './component/Solution/Solution10';
import Solution11 from './component/Solution/Solution11';
import Product10 from './component/Products/Product10';
import Product11 from './component/Products/Product11';


function App() {
  return (

    <BrowserRouter>
      <Navbar />
      <GotoTop />
      <Routes>
        <Route path='/aboutus' Component={AboutUs} />
        <Route path='/' Component={Home} />
        <Route path='/product01' Component={Product01} />
        <Route path='/product02' Component={Product02} />
        <Route path='/product03' Component={Product03} />
        <Route path='/product04' Component={Product04} />
        <Route path='/product05' Component={Product05} />
        <Route path='/product06' Component={Product06} />
        <Route path='/product07' Component={Product07} />
        <Route path='/product08' Component={product08} />
        <Route path='/product09' Component={Product09} />
        <Route path='/product10' Component={Product10} />
        <Route path='/product11' Component={Product11} />

        <Route path='/Solution01' Component={Solution01} />
        <Route path='/Solution02' Component={Solution02} />
        <Route path='/Solution03' Component={Solution03} />
        <Route path='/Solution04' Component={Solution04} />
        <Route path='/Solution05' Component={Solution05} />
        <Route path='/Solution06' Component={Solution06} />
        <Route path='/Solution07' Component={Solution07} />
        <Route path='/Solution08' Component={Solution08} />
        <Route path='/Solution09' Component={Solution09} />
        <Route path='/Solution10' Component={Solution10} />
        <Route path='/Solution11' Component={Solution11} />
        <Route path='/Careers' Component={Careers} />
        <Route path='/privacy_policy' Component={Privacy} />
        <Route path='/Contact' Component={Contact} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
