import React from 'react'

export default function Solution01() {
  
  return (
    <>
     <div className='bg-white w-full overflow-x-hidden pb-10'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Smart Tracking</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'  >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            Smart Introduction
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                Introducing our cutting-edge Smart Tracking solution powered by RFID technology. Our system offers a revolutionary turnkey solution approach to asset management, providing real-time visibility, enhanced security, and streamlined operations for businesses of all sizes.
            </p>
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/asset_audit.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-auto transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/asset_IT.jpg" alt="Img" className={` h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
            
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            Features
            </h2>
            <ul className={`text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-5 transition-transform duration-500 transform `}>
            <li ><strong>Real-time Visibility:</strong> Track your assets anytime, anywhere with our real-time tracking capabilities.</li>
            <li className='mt-2'><strong>Effortless Inventory Management:</strong> Easily manage your inventory with automated tracking and accurate data capture.</li>
            <li className='mt-2'><strong>Enhanced Security:</strong> Protect your assets against theft and unauthorised access with advanced security features.</li>
            <li className='mt-2'><strong>Customizable Alerts:</strong> Receive instant notifications for asset movements, ensuring quick response to potential issues.</li>
            <li className='mt-2'><strong>Comprehensive Reporting:</strong> Gain valuable insights with detailed reports on asset usage, location history, and more.</li>
            </ul> 
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'  >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
                              Industries
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                              Our Smart Asset Tracking solution is tailored to meet the unique needs of various industries, including:
                              <ul className=' list-disc ml-5'>
                                  <li className='mt-2'>Manufacturing</li>
                                      <li className='mt-2'>Logistics and Supply Chain</li>
                                  <li className='mt-2'>Healthcare</li>
                                  <li className='mt-2'> Education</li>
                                  <li className='mt-2'>Retail</li>
                                  <li className='mt-2'> Construction</li>
                              </ul>
                              <span className='mt-3'>And lots more !!!</span>
            </p> 
            <br />
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/asset_realtime.jpg" alt="Img" className={` h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
   
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
                          <img src="./assets/asset_people.jpg" alt="Img" className={` h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5'>
            
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
                                  Why Choose Smart Tracking?

            </h2>
                          <ul className={`text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-5 transition-transform duration-500 transform `}>
                              <li ><strong>Boost Efficiency: </strong> Streamline your operations and improve productivity with automated asset management.
</li>
                              <li className='mt-2'><strong>Reduce Costs:</strong> Minimize asset loss, optimize inventory levels, and reduce manual labor costs.</li>
                              <li className='mt-2'><strong>Enhanced Security:</strong> Protect your assets against theft and unauthorised access with advanced security features.</li>
            </ul> 
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                  <img src="./assets/smarttracking.jpg" alt="Img" className={`w-full h-auto md:h-[400px] transition-transform duration-500 transform `} />
    </div>
    {/* <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/asset_shipping.jpg" alt="Img" className={` h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5'>
            <p className={`text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            Shipping Verification
            </h2>
            <br />
            Automate tracting parts and goods moving between plants for maximum operational efficiency, Easily identify both an item and its location within your warehouse to help workers find what they're looking for and confirm that its the right thing-eliminating errors and providing for flexible storage plans. The NeonaX HUB platform easily integrates into your current software and enables the real-time data collection needed to work at the pace of modern business.
            </p> 
            </div>
        </div>
    </div>  
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            Supply Chain Automation
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
            With end-to-end tracking across beltways and conveyers, operators gain visibility into all parts of a supply chain to know where inventory is located and can see distruptions and slow downs as they happen Unlock next-level efficiency and new insights with visiblity into every thing moving within and across all locations in your supply chain.
            </p> 
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/asset_supply.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/asset_track.jpg" alt="Img" className={` h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5'>
            <p className={`text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            Track Returnanble Assets
            </h2>
            <br />
            NeonaX HUB provides real-time visbility and control of RTIs and rental assets (eg. oil/gas cylinder, crates, racks, pallets etc) in a 3-tier (supplier, distributor, retailer) supply chain. It tracks shipping, returning and receiving of inventory, validates inventory storage, manages the processing of inventory (repair, maintains the viabbility of RTI content, and ensures accountability of supply chain parters)
            </p> 
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            Mustering Roll-Call
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
            The completely of the process multiplies with the size of the facility, the number of floors within buildings, and the amount of assembly (=muster) points <strong>Digitalization of the mustering process</strong> significantly helps to decrease the time needed to complete it and brings real-time updates of those acaounted  for and those persons still missing together with an instant evacuation list.
            </p> 
            <br /> 
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/assets_mustering.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/asset_people.jpg" alt="Img" className={` h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5'>
            <p className={`text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            People Roll-Call
            </h2>
            <br />
            The systems reveal the dwell-time patterns and highlight waiting times, allowing an increase of <strong>Operational efficiency through process, space and workflow optimization</strong> knowing at which location and zone people spent their time can also be used for automatic workhours reconcillation for subcontractors.
            </p> 
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            Uniform & PPE Tracking
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
            By placing a special passive RFID laundry tag in each grown, the industries and laundry service provider can track the location of each item, how many they have sent and recieved and also how many times the items have been used and cleaned, Tags are designed to withstand cleaning procedures and other laundry processes.
            </p> 
            <br /> 
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/asset_uniform.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div> */}
      </div> 
    </>
  )
}

              