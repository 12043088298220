import React from 'react'

export default function Product03() {

  return (
    <>
      <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>6LOWPAN RF Mesh-15db & 28db</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0 py-5'>
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
             6LowPan RF mesh-15 dB
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                The Wi-Smart Utility Network (SUN) click carries the 860-920 MHz RF transceiver and designed to run on a 3.3V power supply. It communicates with the target microcontroller over UART interface. This is one of the most frequently implemented options in smart metering application. 6LoWPAN is a networking technology or adaptation layer that allows IPv6 packets to be carried efficiently within small link layer frames.
            </p>
            <br />
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">APPLICATIONS</h2>
              <ul className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 list-disc ml-5">
                <li>Point-Point and Point-to-Multi Point Applications</li>
                <li>Smart metering and Smart city</li>
                <li>Utility AMR/AMI Network for Electricity, Gas, Water Meters etc.,
                  and Wi-SUN Network and 6LoWPAN</li>
                <li>Automatic Street Light Control</li>
                <li>Home Automation Systems</li>
            </ul>
            </div>
            <div className='mt-5 lg:mt-0 xl:ml-10'>
            <img src="./assets/p3img-removebg-preview.png" alt="Img" className={`mr-100 h-[400px] md:w-full xl:w-[500px] md:h-[400px] lg:mt-20 transtion-transform duration-500 transform `} />
            </div>
        </div>

          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0 py-5' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">FEATURES</h2>
              <ul className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 list-disc ml-5">
                <li>32-bit timer function </li>
                <li>Transmission RAM/Receiving RAM: both 256 bytes</li>
                <li>16-bit/32-bit auto CRC function</li>
                <li>Address filter & auto ACK reply function</li>
                <li>Antenna diversity function</li>
                <li>Auto CSMA-CA function</li>
              </ul>
            </div>
            <div className={`mt-5 lg:mt-0 xl:ml-10 transtion-transform duration-500 transform `} >
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">SPECIFICATION</h2>
              <ul className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 list-disc ml-5">
                <li>Renesas RL78/G1H, 16-bit MCU with integrated sub GHz Transceiver</li>
                <li>2FSK/4FSK with support for data rates 50/100/200 kbps</li>
                <li>Factory calibrated High TX Power of up to 15 dBm and RX Antenna diversity</li>
                <li>Industry’s lowest power consumption in standby mode (6 mA at 3.3V)</li>
                <li>Simple UART interface for easy OEM integration</li>
              </ul>
            </div>
          </div>
    </div>

        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0 py-5' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">
                6LowPan RF mesh-28 dB
              </h2>
              <br />
              <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                The Wi-Smart Utility Network (SUN) click carries the 860-920 MHz RF transceiver and designed to run on a 3.3V power supply. It communicates with the target microcontroller over UART interface. This is one of the most frequently implemented options in smart metering application. 6LoWPAN is a networking technology or adaptation layer that allows IPv6 packets to be carried efficiently within small link layer frames. <br /> The module transmit high power path with a +28dBm PA and Tx harmonic output filtering, a transmit bypass through path with Tx harmonic output filtering, and a receive path with a low noise amplifier (LNA) with bypass mode. The FEM also features a low insertion loss/high isolation SP3T switch and separate Rx/Tx 50Ω ports, simplifying matching and providing input and output signals for both the Tx and Rx paths.
              </p>
              <br />
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">APPLICATIONS</h2>
              <ul className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 list-disc ml-5">
                <li>Point-Point and Point-to-Multi Point Applications</li>
                <li>Smart metering and Smart city</li>
                <li>Utility AMR/AMI Network for Electricity, Gas, Water Meters etc.,
                  and Wi-SUN Network and 6LoWPAN</li>
                <li>Automatic Street Light Control</li>
                <li>Home Automation Systems</li>
              </ul>
            </div>
            <div className={`mt-5 lg:mt-0 xl:ml-10 transtion-transform duration-500 transform `} >
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">FEATURES</h2>
              <ul className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 list-disc ml-5">
                <li>32-bit timer function </li>
                <li>Transmission RAM/Receiving RAM: both 256 bytes</li>
                <li>16-bit/32-bit auto CRC function</li>
                <li>Address filter & auto ACK reply function</li>
                <li>Antenna diversity function</li>
                <li>Auto CSMA-CA function</li>
              </ul>
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">SPECIFICATION</h2>
              <ul className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 list-disc ml-5">
                <li>Renesas RL78/G1H, 16-bit MCU with integrated sub GHz Transceiver</li>
                <li>2FSK/4FSK with support for data rates 50/100/200 kbps</li>
                <li>Factory calibrated High TX Power of up to 15 dBm and RX Antenna diversity</li>
                <li>Industry’s lowest power consumption in standby mode (6 mA at 3.3V)</li>
                <li>Simple UART interface for easy OEM integration</li>
              </ul>
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">Low Power Consumption </h2>
              <ul className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 list-disc ml-5">
                <li>RF receiving current: 6.9 mA (TYP) @3.0 V, 100 kbps, 2FSK/at STOP mode of MCU </li>
                <li>RF transmission current: 21 mA (TYP) @3.0 V, 100 kbps, 2FSK, +10 dBm/at STOP mode of MCU</li>
              </ul>
            </div>
          </div>
        </div>
    </div> 
    </>
  )
}
