import React from 'react'

export default function Solution04() {
 

  return (
    <>
       <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Smart Gas & Water Meter</h1>
            </div>
        </div>
           
          {/* <div className="relative max-w-full h-[500px] mt-10 bg-[#fff] overflow-x-hidden" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <img src="./assets/s4img.png" alt="" className={`w-full h-full object-fill transition-transform duration-500 transform ${isHovered ? ' left-0 -translate-x-0' : ' right-0 translate-x-full' }`} />
      </div> */}
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 pb-5 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'  >
            <div className={`p-2  lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">
                Smart Gas and Water Metering
              </h2>
              <br />
              <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                Smart Gas and Water metering communications solutions that provide end-to-end quality of service using optimal technology. Neonax provide cost effective communication modules which support both in Hardware and Software along with Network management system for Smart Gas and Water metering solution. We support communication technologies like G3PLC, RS485,RS232 with low total cost of ownership for customer.
              </p>
              <br />
              
            </div>
            <div className='pt-5 lg:mt-0'>
              <h2 className="text-[24px] text-[#444444] font-medium mt-5">
                Features
              </h2>
              <br />
              <ul className="font-normal list-disc pl-5 space-y-1 text-base leading-7 text-justify text-[#444444]">
                <li>	Precise measurement and data analysis of different liquid types including water, petroleum products and organic oils.</li>
                <li>	Leakage detection</li>
                <li>Contamination detection</li>
                <li>Intelligent liquid storage systems</li>
              </ul>
            </div>
          </div>
        </div>


        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 pb-5 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2  lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Smart Gas Meter 
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                The AMR (Automatic Meter Reading) System offers utilities the capability to perform meter readings without the need for home entry. Built on Narrow Band IoT technology, the Wave FN AMR solution delivers rapid and trustworthy meter readings to energy providers.
            </p>
            <br />
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Features
            </h2>
            <br />
            <ul className="font-normal list-disc pl-5 space-y-1 text-base leading-7 text-justify text-[#444444]">
                <li>M-Bus Slave meets standard EN1434-3 and 13757</li>
                <li>Configurable baud rates upto 19, 200</li>
                <li>Supply from battery- Meter Bus active for data transmission</li>
                <li>Collision detection</li>
                <li>Tamper detection</li>
                <li>Operating Temperature: 0 - 550 C</li>
                <li>Operating Humidity: 0 – 95%rH</li>
                <li>non condensing IP65</li>
            </ul>
            </div>
            <div className='pt-5 lg:mt-0'>
            <img src="./assets/gasmeter.png" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform object-contain duration-500 transform `} />
            </div>
        </div>
    </div>


        <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/watermeter.png" alt="Img" className={` h-[400px] md:w-full xl:w-full md:h-[350px] object-contain py-2 transition-transform duration-500 transform `} />
            </div>

            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5'  >
              <p className={`text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-200 transform `}>
                <h2 className="text-[24px] text-[#444444] font-medium mt-5">
                  Smart Water Meter
                </h2>
                <br />
                <ul className="font-normal list-disc pl-5 space-y-1 text-base leading-7 text-justify text-[#444444]">
                  <li>Improved revenue by automatic finding and reducing of leakage</li>
                  <li>Enable remote automatic water reading</li>
                  <li>Accurate and correct billing</li>
                  <li>No under reporting of consumption as in manual reading</li>
                  <li>Finding the demand of water required or Demand forecast</li>
                  <li>The lack of access to water meters makes it impossible to read the meter data, but it is possible on a smart meter
                    </li>
                  <li>With help of centralized water meter reading, we can get more insight on water usage, water theft, water leakage and unbilled water data etc..</li>
                  
                </ul> </p>
            </div>
          </div>
          <div className='mt-5'>
            <img src="./assets/watermeter02.png" alt="" className={` h-[400px] md:w-full xl:w-full object-contain py-2 transition-transform duration-500 transform `} />
          </div>
        </div>
    </div> 
    </>
  )
}
