import React from 'react'

export default function Product05() {
  
  return (
    <>
      <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
          <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
          <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
            <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Handheld RFID Readers and RFID-Enabled Scanner</h1>
          </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <h1 className='mx-5 md:mx-0 text-[24px] text-[#444444] pt-10'>Exark650 Handheld reader </h1> <br />
          <p className="font-normal list-disc space-y-1 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8 mx-5 md:mx-0">
            The Exark650 is a powerful handheld RFID reader designed to streamline your asset management processes with ease and efficiency. With its sleek design and advanced features, the Exark650 offers unparalleled performance in a compact and user-friendly package.
          </p>
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
              <h1 className=' text-[24px] text-[#444444] mt-5'>Handheld and Mobile application</h1> <br />
              <ul className="font-normal list-disc space-y-1 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8 ml-5" >
                <li>Customised based application</li>
                <li>Tagged items captured using the application</li>
                <li>Asset transfer/Receive</li>
                <li>Perform  Asset  Audit at different locations</li>
                <li>Mobile login & Manual operations</li>
                <li>Damage & maintenance management</li>
                <li>Asset Status Updates</li>
              </ul>
            </div>
            <div className='mt-5 lg:mt-0 flex flex-row justify-center lg:justify-normal'>
              <img src="./assets/Picture2.png" alt="Img" className={`mr-100 h-[400px] md:[400px] xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>
          </div>
        </div>

        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-10 flex flex-row justify-center lg:justify-normal'>
              <img src="./assets/image__8_-removebg-preview.png" alt="Img" className={` h-[400px] md:w-[400px] xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
              <h1 className={`mx-5 md:mx-0 text-[24px] text-[#444444] mt-10 transition-transform duration-500 transform `}>Key Features:</h1>
              <ul className={`font-normal list-disc space-y-1 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
                <li><strong>Advanced Technology:</strong> Our RFID handheld readers utilise state-of-the-art technology to deliver fast and accurate data capture, even in challenging environments.</li>
                <li><strong>Versatility:</strong> With support for multiple RFID tag types and protocols, our handheld readers are versatile enough to meet a wide range of tracking and identification needs.</li>
                <li><strong>Durability:</strong> Built to withstand the demands of industrial environments, our handheld readers are rugged and reliable, ensuring long-lasting performance.</li>
                <li><strong>Ease of Use:</strong> Intuitive interfaces and user-friendly designs make our handheld readers easy to operate, minimising training time and increasing productivity.</li>
                <li><strong>Wireless Connectivity:</strong> Seamlessly connect our handheld readers to your existing systems via Wi-Fi or Bluetooth, enabling real-time data transfer and integration with your backend software.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
              <h1 className='mx-5 md:mx-0 text-[24px] text-[#444444] mt-5'>Applications:</h1> <br />
              <ul className="font-normal list-disc space-y-1 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8" >
                <li><strong>Inventory Management:</strong> Streamline inventory counting, stock replenishment, and asset tracking in retail, warehouses, and distribution centers.</li>
                <li><strong>Asset Tracking:</strong> Keep track of equipment, tools, and vehicles in manufacturing, construction, and logistics.</li>
                <li><strong>Healthcare:</strong> Track medical equipment, supplies, and patient records in hospitals, clinics, and healthcare facilities.</li>
                <li><strong>Security:</strong> Monitor access points, track personnel, and manage security assets in commercial and industrial settings.</li>
              </ul>
            </div>
          </div>
        </div>

{/* 
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/image__6_-removebg-preview.png" alt="Img" className={` h-[400px] md:w-full xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
              <h1 className={`mx-5 md:mx-0 text-[24px] text-[#444444] mt-10 transition-transform duration-500 transform `}>TSL 2128P Handheld Reader</h1>
              <p className={`font-normal list-disc space-y-1 text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
                The TSL 2128P delivers high-performance, long-range RAIN RFID reading with the convenience of TSL's connectivity and charging system. This device provides in-reader tag de- duplication and an embedded micro SD card to enable a powerful batch collection mode of operation.
              </p>
            </div>
          </div>
        </div>


        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
              <h1 className='mx-5 md:mx-0 text-[24px] text-[#444444] mt-5'>TSL 2166 Handheld Reader</h1> <br />
              <p className="font-normal list-disc space-y-1 text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8" >
                The TSL 2166 delivers high-performance, long-range RAIN RFID reading in a tough and rugged form factor while also providing the convenience of TSL's connectivity and charging system. This device is highly resistant to water, dust, and mechanical trauma.
              </p>
            </div>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/image__5_-removebg-preview.png" alt="Img" className={`mr-100 h-[400px] md:w-full xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>
          </div>
        </div>


        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/image__4_-removebg-preview.png" alt="Img" className={` h-[400px] md:w-full xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
              <h1 className={`mx-5 md:mx-0 text-[24px] text-[#444444] mt-10 transition-transform duration-500 transform `}>Zebra MC3330XR UHF RFID READER</h1>
              <p className={`font-normal list-disc space-y-1 text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
                The MC3330xR is packed with features to bring a new level of efficiency and accuracy to inventory and asset management applications. You get the same proven lightweight ergonomic design and superior RFID read performance, now with Zebra's latest, most powerful and secure Android architecture.
              </p>
            </div>
          </div>
        </div>


        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
              <h1 className='mx-5 md:mx-0 text-[24px] text-[#444444] mt-5'>Zebra RFD8500 RFID/1D/2D Sled</h1> <br />
              <p className="font-normal list-disc space-y-1 text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8" >
                Just pair the Bluetooth-enabled RFD8500 with a compatible Zebra or third-party mobile device and you're ready to go. You can attach the mobile device to the RFD8500 sled for a complete one-piece solution or workers can put the mobile device in a pocket and use the RFD8500 in standalone mode.
              </p>
            </div>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/image__3_-removebg-preview.png" alt="Img" className={`mr-100 h-[400px] md:w-full xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>
          </div>
        </div>



        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/image__2_-removebg-preview.png" alt="Img" className={` h-[400px] md:w-full xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
              <h1 className={`mx-5 md:mx-0 text-[24px] text-[#444444] mt-10 transition-transform duration-500 transform `}>Honeywell IH25 RFID Handheld Reader</h1>
              <p className={`font-normal list-disc space-y-1 text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
                Retailers can count inventory in the store and warehouse 25 times faster than with barcodes, often in a matter of a few hours, enabling more frequent inventory updates and closer management of inventory levels. Lightweight and ergonomically designed, the Honeywell IH25, paired with a Honeywell high performance mobile computer, offers best in class UHF plus 1D/2D scanning, RFID range and speed
              </p>
            </div>
          </div>
        </div>

        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
              <h1 className='mx-5 md:mx-0 text-[24px] text-[#444444] mt-5'>Chainway C72 Handheld Reader</h1> <br />
              <p className="font-normal list-disc space-y-1 text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8" >
                Chainway C72 is an Android-rugged mobile computer. It features a powerful processor, 8000mAh battery, and superb UHF RFID capability. It can read tags in bulk from long-distance which enables it to be deployed in asset management, retail, warehousing, fleet management, etc..
              </p>
            </div>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/image__1_-removebg-preview.png" alt="Img" className={`mr-100 h-[400px] md:w-full xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>
          </div>
        </div> */}


      </div>
    </>
  )
}
