import React from 'react'

export default function Product04() {

  return (
    <>
      <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Bluetooth / Wifi based IoT App</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h1 className=' md:mx-0 text-[24px] text-[#444444] mt-5'>Processor/Controllers Used :</h1> <br/>
              <ul className="font-normal list-disc pl-5 space-y-1 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8" >
            <li>ESP32</li>
             <li>4MB Flash 8 MB Flash AC</li>
             <li>Control</li>
             <li>Board Size 45 mm Día</li>
             <li>Power 120 230 V AC input</li>
             <li>Super cap based RTC backup</li>
            
            </ul>
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/p4img-removebg-preview.png" alt="Img" className={`mr-100 h-[400px] md:w-full xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/p4img-removebg-preview.png" alt="Img" className={` h-[400px] md:w-full xl:w-[450px] md:h-[350px] py-5 transition-transform duration-500 transform `} />
            </div>           
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
            <h1 className={`mx-5 md:mx-0 text-[24px] text-[#444444] mt-10 transition-transform duration-500 transform `}>Execution Highlights</h1>
              <ul className={`font-normal list-disc pl-5 space-y-1 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
            <li>ESP32</li>
            <li>Duration (Prototype) = 2 months</li>
             <li>Schematics, Layout</li>
             <li>Supply Chain</li>
             <li>Proto typing by EMS Partners</li>
             <li>Bring up, Functional tests & Validation</li>
             <li>Firmware</li>
             <li>Impedance controlled Multilayer PCBs</li>
             <li>Systems with multiple PCBs</li>
            </ul>
            </div>
        </div>
    </div>
    </div> 
    </>
  )
}
