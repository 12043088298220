import React from 'react'

export default function Product08() {

  
  return (
    <>
      <div className='bg-white w-full pb-10 overflow-x-hidden'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>HRMS - TIATECH</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] py-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            WHY HRMS?
            </h2>
            <br />
            <p className="font-normal text-base leading-6 text-justify text-[#444444]">
            HRMS is a compact application of different features, which helps to empower the
            HR Administration works of an organization — by organizing, managing and
            automating HR processes.
            </p> 
            <br />
            <p className="font-normal text-base leading-6 text-justify text-[#444444]">
            With features like Employee Management, Roaster Management, Pay Roll
            Management, Roles and Leave Management, the main motive behind Smart HRMS
            application was, to fundamentally automate all the time consuming repetitive tasks
            of HR managements, so that they can strategically focus on key business
            assignments of their organization.
            </p> 
            </div>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/future-HIMS.webp" alt="Img" className={`mr-100 h-[400px] md:w-full xl:w-[350px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/hims-image.webp" alt="Img" className={` h-[400px] md:w-full xl:w-[350px] md:h-[350px] py-2 transition-transform duration-500 transform `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
            <p className={`font-normal text-base leading-6 text-justify text-[#444444] mt-10 transition-transform duration-200 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            What size of company is Smart HRMS designed for?
            </h2>
            <br />
            With its wide spectrum of modules this HRMS
            application is an apt fit for companies of
            various sizes. Whether it is a startup or a
            multinational company, smart HRMS can be
            customized for every organizational need.
            </p> 
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] py-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-8">
            Advantages of Smart HRMS?
            </h2>
            <ul className="font-normal list-disc pl-5 space-y-1 text-base leading-7 text-justify text-[#444444]" >
             <li>Improved access to information.</li>
             <li>Manage employees effectively.</li>
             <li>Reduce redundant data entry and processes.</li>
             <li>Simplify and speed up day to day administration tasks.</li>
             <li>Increase efficiency and quality of HR decision-making.</li>
             <li>Get a unique ability to automate employee roasters.</li>
             <li>Streamline the recruiting process.</li>
             <li>Improve communication within your organization.</li>
             <li>Customized to an organization's unique needs.</li>
            </ul>
            </div>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/HMIS.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full xl:w-[350px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
      </div>
    </>
  )
}
