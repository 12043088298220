import React from 'react'

export default function Product01() {
  
  return (
    <>
      <div className='bg-white w-full pb-10 overflow-x-hidden'>
        <div className="relative max-w-full lg:pt-28">
          <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>GATEWAY - DCU -  IoT Hub</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            GATEWAY - DCU -  IoT Hub
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
            DCU will act as a connectivity partner for Smart Streetlight, Automation and Smart metering solution. DCU collects information and data from Energy Meters, Water meter and Gasmeter.
            </p> 
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
            Data will be saved into the local storage before forwarding data to the utility. The built in Web Server feature increase flexibility and convenience, making it easy to configure and maintain the systemvia internet.
            </p> 
            </div>
            <div className='mt-5 lg:mt-0 min-[375px]:ml-[50px] min-[425px]:ml-[75px] md:ml-0'>
            <img src="./assets/DCU-removebg-preview.png" alt="Img" className={`mr-100 h-[400px] md:w-full xl:w-[350px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/iothub-removebg-preview.png" alt="Img" className={` h-[400px] md:w-full xl:w-[350px] md:h-[350px] py-2 transition-transform duration-500 transform `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' >
            <p className={`text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-200 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-10">
            IoT Hub
            </h2>
            <br />
            NeonaX's cost optimized metering communications solutions DCU, offer scalable and secure technology for Advanced Metering Infrastructure (AMI) deployments.. SC20
            </p> 
            </div>
        </div>
    </div>
      </div>
    </>
  )
}
