import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from "react-router-dom";


export default function Home() {
  const [counterOn, setCounterOn] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const containerRef = useRef(null);

  const handleScrollButtonClick1 = (direction) => {
    if (containerRef.current) {
      const container = containerRef.current;
      const scrollOffset =
        direction === "back" ? -container.offsetWidth : container.offsetWidth;

      container.scrollTo({
        left: container.scrollLeft + scrollOffset,
        behavior: "smooth",
      });
    }
  };


  const images = [
    {
      src: "./assets/cardimg1.png",
      title: "Smart Tracking",
      link: "/Solution01"
    },
    {
      src: "./assets/cardimg3.png",
      title: "Embedded Systems",
      link: "/product11"
    },
    {
      src: "./assets/cardimg04.jpg",
      title: "Perimeter Security System",
      link: "/Solution09"
    },
    {
      src: "./assets/cardimg05.jpg",
      title: "IoT Hardware & Software",
      link: "/Product01"
    },
  ];
  const images1 = [
    {
      src: "./assets/smart_street_light.jpg",
      title: "Smart Street Light",
      link: "/Solution06"
    },
    {
      src: "./assets/asset_elecricity.jpg",
      title: "Smart Electricity Meter",
      link: "/Solution03"
    },
    {
      src: "./assets/asset_gas.jpg",
      title: "Smart Gas and Water Meter",
      link: "/Solution04"
    },
    {
      src: "./assets/cardimg2.png",
      title: "Mobile App Development",
      link: "/Solution08"
    },
  ];

  const images2 = [
    {
      src: "./assets/cardimg08.jpg",
      title: "Cloud Application development",
      link: "/Solution08"
    },
    {
      src: "./assets/evcharger.jpg",
      title: "EV Charger",
      link: "/Product07"
    },
  ]
  // slider video
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [autoRotation, setAutoRotation] = useState(true);

  // const handleSlideChange = (swiper) => {
  //   setActiveSlideIndex(swiper.realIndex);
  // };
  const content = [
    {
      title: "Smart Tracking ",

      description:
        "Introducing our cutting-edge Smart Tracking solution powered by RFID technology. Our system offers a revolutionary turnkey solution approach to asset management, providing real-time visibility, enhanced security, and streamlined operations for businesses of all sizes.",
      link: "/Solution01",
    },
    {
      title: "Smart Trash Bin",
      description:
        "An innovative waste management solution to Monitor fill levels, optimize collection routes etc Equipped with technology to enhance efficiency and sustainability of waste management.",
      link: "/Solution07",
    },
    {
      title: "Software Solution",
      description:
        "Benefit from cost-effective open-source app development. Leverage big data for increased information power. Access data securely on any device with cloud. Invest in mobile apps for business productivity. Microsoft offered unified solutions for web, apps, and analytics.",
      link: "/Solution08",
    },
    {
      title: "Smart Environmental Sensor",
      description:
        "Discover the future of sustainability with our Smart Environmental Sensor. Monitor air quality, temperature, CO2, light intensity, noise, and occupancy. Track energy usage, water quality, and receive real-time alerts. Embrace eco-living effortlessly!",
      link: "/Solution05",
    },
    {
      title: "Smart Parking",
      description:
        "Smart parking aims to optimize parking management and utilization. smart parking systems help drivers find available parking spaces quickly, reducing congestion, improving efficiency, and enhancing the overall urban mobility experience",
      link: "/Solution02",
    },
    {
      title: "Smart Gas & Water Meter",
      description:
        "NeonaX offers cost-effective Smart Gas and Water metering solutions with precise measurement, data analysis for various liquids, leakage, and contamination detection. Our communication modules support RF and BLE technologies, ensuring optimal performance and low total cost of ownership.",
      link: "/Solution04",
    },
  ];

  const handleSlideHover = (index) => {
    setActiveSlideIndex(index);
    setAutoRotation(false); // Pause auto rotation
  };
  const handleSlideLeave = () => {
    setAutoRotation(true); // Resume auto rotation
  };

  useEffect(() => {
    // Automatic rotation logic
    const interval = setInterval(() => {
      if (autoRotation) {
        const nextIndex = (activeSlideIndex + 1) % content.length;
        setActiveSlideIndex(nextIndex);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [activeSlideIndex, autoRotation, content.length]);

  // const handleSlideHover = (index) => {
  //   setActiveSlideIndex(index);
  // };
  // useEffect(() => {
  //   // Automatic rotation logic
  //   const interval = setInterval(() => {
  //     const nextIndex = (activeSlideIndex + 1) % content.length;
  //     setActiveSlideIndex(nextIndex);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [activeSlideIndex, content.length]);



  const imagescard = [
    {
      src: "./assets/cardd1.png",
    },
    {
      src: "./assets/cardd2.png",
    },
    {
      src: "./assets/cardd3.png",
    },
    {
      src: "./assets/cardd4.png",
    },
    {
      src: "./assets/cardd5.png",
    },
    {
      src: "./assets/cardd6.png",
    },
  ];

  // end

  return (
    <>
      <div className="overflow-x-hidden w-auto">


        <div className="bg-gradient-to-b  w-full from-purple-900 via-purple-900 to-transparent relative ">
          <video
            className="object-cover w-full h-[80vh] lg:h-[100vh] z-0 opacity-90"
            autoPlay
            muted
            loop
            src="./assets/Earth-animi.mp4"
            type="video/mp4"
          ></video>

          <div className=" hidden lg:block absolute top-[100px]  mx-5 lg:mx-0 lg:top-52 xl:top-[280px] w-[250px] lg:w-[400px] xl:w-[500px] lg:ml-[calc((100%-950px)/2)] xl:ml-[calc((100%-1200px)/2)]">
            <h2 className="text-[28px] lg:text-[42px]  text-white lg:w-[600px]">
              {content[activeSlideIndex].title}
            </h2>

            <p className="text-sm  text-white leading-9 lg:text-[18px] text-justify">
              {content[activeSlideIndex].description}
            </p>
            <br />
            <Link to={content[activeSlideIndex].link}> <button
              className="border border-[#028FC8] text-white text-[20px] p-3 bg-[#028FC8] no-underline mt-5 rounded-[5px] cursor-pointer "

            >
              Read more
            </button></Link>
          </div>
          <div className=" hidden lg:block absolute top-[300px] -right-16 xl:right-1 mx-5 w-[250px] lg:w-[650px] xl:top-[300px] lg:ml-[calc((100%-950px)/2)] xl:mr-[calc((100%-1200px)/2)]">
            <section id="slideshow">
              <div class="entire-content" >
                <div className="content-carrousel">
                  {content.map((item, index) => (
                    <figure
                      className={`shadow ${index === activeSlideIndex ? 'active' : ''}`}
                      key={index}
                      // onSlideChange={handleSlideChange}

                      onMouseEnter={() => handleSlideHover(index)}
                      onMouseLeave={handleSlideLeave}
                    >
                      <img src={`./assets/cardd${index + 1}.png`} alt="" />
                    </figure>
                  ))}
                </div>
              </div>
            </section>
          </div>

          {/* Mobile view  */}
          <div className="  lg:hidden absolute top-[80px] md:mr-6 mx-5 w-[250px] lg:w-[500px] lg:ml-[calc((100%-950px)/2)] xl:ml-[calc((100%-1200px)/2)]">
            <h3 className="text-[28px] lg:text-[48px] text-white pt-8">
              Future Digital World
            </h3>
            <p className="text-[14px] lg:text-[18px] font-normal  text-justify text-white  leading-8 pt-2">
              We are an experienced team members with advance ideas on business strategies in both private and government sector providing with latest technology on different vertical of business
            </p>
            <div className="pt-6">
              {" "}
              <Link to='/aboutus'> <button
                className="border border-[#028FC8] text-white text-[20px] bg-[#028FC8] no-underline  rounded-[5px] cursor-pointer p-2 "  >
                Read more
              </button></Link>
            </div>
          </div>
        </div>

        <div className="!bg-white pb-10 pt-10" id="Home">
          <div className="text-center " id="solution">
            <h1 className="text-[40px] font-light text-[#444444]">
              Our <span className="text-[#028FC8] font-medium"> Solution</span>
            </h1>
          </div>

          {/* image card */}
          <div className="mx-16 md:mx-5 pb-10 lg:pl-[calc((100%-1000px)/2)]   lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
            <div className="grid grid-cols-1 md:grid-cols-4 md:mt-[30px] ">
              {images.map((image, index) => (
                <div key={index} className="block">
                  <h2 className="text-[13px] lg:text-lg font-medium text-[#444444] ml-2 lg:ml-2">
                    {image.title}
                  </h2>
                  <div className="relative overflow-hidden  md:mr-2 lg:mr-[10px] border-2 border-white rounded-3xl">
                    <Link to={image.link}> <img
                      src={image.src}
                      alt="img"
                      className="w-[285px] h-[186px] transition-all duration-300 transform scale-100 hover:scale-110 border border-white rounded-3xl"
                    /></Link>
                  </div>
                </div>
              ))}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 md:mt-[20px]">
              {images1.map((image, index) => (
                <div key={index} className="block">
                  <h2 className="text-[13px] lg:text-lg font-medium text-[#444444] ml-2 lg:ml-2">
                    {image.title}
                  </h2>
                  <div className="relative overflow-hidden  md:mr-2 lg:mr-[10px] border-2 border-white rounded-3xl">
                    <Link to={image.link}>  <img
                      src={image.src}
                      alt="img"
                      className="w-[285px] h-[186px] transition-all duration-300 transform scale-100 hover:scale-110 border border-white rounded-3xl"
                    /> </Link>
                  </div>
                </div>
              ))}
            </div>

            <div className="block md:flex flex-row justify-center  md:mt-[20px]">
              {images2.map((image, index) => (
                <div key={index} className="block">
                  <h2 className="text-[13px] lg:text-lg font-medium text-[#444444] ml-2 lg:ml-1">
                    {image.title}
                  </h2>
                  <div className="relative overflow-hidden  md:mr-2 lg:mr-[10px] border-2 border-white rounded-3xl">
                    <Link to={image.link}>  <img
                      src={image.src}
                      alt="img"
                      className="w-[285px] h-[186px] transition-all duration-300 transform scale-100 hover:scale-110 border border-white rounded-3xl"
                    /> </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* slider */}

          <div
            className="relative max-w-full h-auto bg-[#fff] overflow-x-hidden mt-5 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]"

          >
            <div className="grid grid-cols-1 lg:grid-cols-2 mx-5">
              <div
                className={`  p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}
              >
                <h2 className="text-4xl text-[#555555] mt-10">
                  WE DO NOT DESIGN{" "}
                  <span className="text-[#028FC8] font-medium">PRODUCTS </span>
                  WE DESIGN <span className="text-[#028FC8] font-medium">DREAMS</span>
                </h2>
                <br />
                <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                  Neonax is a global IT solutions provider with its headquarters
                  located in Dubai. With the backbone of a bunch of experienced
                  professionals in the software industry, we are inspired to
                  diversify our skills in various domains such as Asset Tracking &
                  Management System, Perimeter Security System, ERP Solutions,
                  Mobile Application Development, Web Development, Branding, SEO,
                  Internet marketing, IoT solutions, etc.
                </p>{" "}
                <br />
                <Link to='/aboutus'>
                  <button
                    className="border border-[#028FC8] text-white  w-[168px] h-[48px] text-[20px] bg-[#028FC8] no-underline  rounded-[5px] cursor-pointer "
                  >
                    About Us
                  </button></Link>
              </div>
              <div className="mt-5  lg:mt-0 lg:ml-5">
                <img
                  src="./assets/designhome.jpg"
                  alt="Img"
                  className={`  
           h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `}
                />
              </div>
            </div>
          </div>

          {/* swiper */}
          <div className="text-center mt-5">
            <h1 className="text-[40px] font-light text-[#444444]">
              Our Clients <span className="text-[#028FC8] font-medium">and Partners</span>
            </h1>
          </div>
          <div className="mt-10 flex max-w-full lg:ml-[calc((100%-900px)/2)] lg:mr-[calc((100%-900px)/2)] xl:ml-[calc((100%-1280px)/2)] xl:mr-[calc((100%-1280px)/2)] carousel">
            <button onClick={() => handleScrollButtonClick1("back")}>
              <ArrowBackIosIcon className="icon text-[#008FC9] !text-[42px] rounded-full invisible lg:visible" />
            </button>
            <div className="carousel space-x-2 -ml-5 md:ml-0" ref={containerRef}>
              <div className="carousel-item">
                <div className="w-full md:mr-5">
                  <div className="">
                    <img
                      src="./assets/neona_logo.png"
                      className='  w-[320px] h-[100px] object-contain border rounded-md'
                      alt=""
                    /></div>
                  <hr className="my-5 border-[#444444] w-[320px] text-[#444444]" />
                  <h1 className="text-[20px] font-semibold text-gray-700 text-center ">Neona Embedding Imagination</h1>
                </div>
              </div>

              <div className="carousel-item">
                <div className="w-full md:mr-5">
                  <div className=" ">
                    <img
                      src="./assets/DEWA_Logo.jpg"
                      className='  w-[320px] h-[100px] object-cover border rounded-md'
                      alt=""
                    /></div>
                  <hr className="my-5 border-[#444444] w-[320px] text-[#444444]" />
                  <h1 className="text-[20px] font-semibold text-gray-700 text-center ">DEWA</h1>
                </div>
              </div>

              <div className="carousel-item">
                <div className="w-full md:mr-5">
                  <div className=" bg-slate-300 rounded-md">
                    <img
                      src="./assets/digitaldewa.png"
                      className='  w-[320px] h-[100px] object-contain border  rounded-md'
                      alt=""
                    /></div>
                  <hr className="my-5 border-[#444444] w-[320px] text-[#444444]" />
                  <h1 className="text-[20px] font-semibold text-gray-700 text-center ">Digital DEWA</h1>
                </div>
              </div>

              <div className="carousel-item">
                <div className="w-full md:mr-5">
                  <div className=" ">
                    <img
                      src="./assets/Site-Smartxhub_ Logo.png"
                      className='  w-[320px] h-[100px] object-contain border  rounded-md'
                      alt=""
                    /></div>
                  <hr className="my-5 border-[#444444] w-[320px] text-[#444444]" />
                  <h1 className="text-[20px] font-semibold text-gray-700 text-center ">Smart X Hub</h1>
                </div>
              </div>

              <div className="carousel-item">
                <div className="w-full md:mr-5">
                  <div className=" ">
                    <img
                      src="./assets/MEERANA Logo final-04.png"
                      className='w-[320px] h-[100px] object-cover border  rounded-md'
                      alt=""
                    /></div>
                  <hr className="my-5 border-[#444444] w-[320px] text-[#444444]" />
                  <h1 className="text-[20px] font-semibold text-gray-700 text-center ">Meerana Technology</h1>
                </div>
              </div>

              <div className="carousel-item">
                <div className="w-full md:mr-5">
                  <div className=" ">
                    <img
                      src="./assets/thedigitalx_logo.jpg"
                      className='  w-[320px] h-[100px] object-cover border rounded-md'
                      alt=""
                    /> </div>
                  <hr className="my-5 border-[#444444] w-[320px] text-[#444444]" />
                  <h1 className="text-[20px] font-semibold text-gray-700 text-center ">Digital X</h1>
                </div>
              </div>

              <div className="carousel-item">
                <div className="w-full md:mr-5">
                  <div className=" ">
                    <img
                      src="./assets/hilal _logo.jpg"
                      className='  w-[320px] h-[100px] object-contain border rounded-md'
                      alt=""
                    /> </div>
                  <hr className="my-5 border-[#444444] w-[320px] text-[#444444]" />
                  <h1 className="text-[20px] font-semibold text-gray-700 text-center ">Mohammed Hilal Group</h1>
                </div>
              </div>

              <div className="carousel-item">
                <div className="w-full md:h-auto bg-white mr-5">
                  <div className=" ">
                    <img
                      src="./assets/Moro_logo1.jpg"
                      className='  w-[320px] h-[100px] object-cover border rounded-md'
                      alt=""
                    /></div>
                  <hr className="my-5 border-[#444444] w-[320px] text-[#444444]" />
                  <h1 className="text-[20px] font-semibold text-gray-700 text-center ">Moro</h1>
                </div>
              </div>

              <div className="carousel-item">
                <div className="w-full md:mr-5">
                  <div className=" ">
                    <img
                      src="./assets/infrax_logo.jpg"
                      className='  w-[320px] h-[100px] object-contain border  rounded-md'
                      alt=""
                    /></div>
                  <hr className="my-5 border-[#444444] w-[320px] text-[#444444]" />
                  <h1 className="text-[20px] font-semibold text-gray-700 text-center ">Infrax</h1>
                </div>
              </div>
            </div>
            <button onClick={() => handleScrollButtonClick1("forward")}>
              <ArrowForwardIosIcon className="icon text-[#008FC9] !text-[42px] rounded-full invisible lg:visible" />
            </button>
          </div>

          {/* number */}
          <div className="mx-12 md:mx-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)] ">
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <div className="grid grid-cols-1 md:grid-cols-4">
                <div className="text-[64px] p-4 font-medium text-[#008FC9]">
                  <h4>
                    {counterOn && (
                      <CountUp start={0} end={250} duration={3} delay={0} />
                    )}
                    +
                  </h4>
                  <p className="text-[24px] text-[#787878]">Clients Worldwide</p>
                </div>
                <div className="text-[64px] p-4 font-medium text-[#008FC9]">
                  <h4>
                    {counterOn && (
                      <CountUp start={0} end={300} duration={3} delay={0} />
                    )}
                    +
                  </h4>
                  <p className="text-[24px] text-[#787878]">Projects Delivered</p>
                </div>
                <div className="text-[64px] p-4 font-medium text-[#008FC9]">
                  <h4>
                    {counterOn && (
                      <CountUp start={0} end={40} duration={3} delay={0} />
                    )}
                    +
                  </h4>
                  <p className="text-[24px] text-[#787878]">In House Resources</p>
                </div>
                <div className="text-[64px] p-4 font-medium text-[#008FC9]">
                  <h4>
                    {counterOn && (
                      <CountUp start={0} end={12} duration={3} delay={0} />
                    )}
                    +
                  </h4>
                  <p className="text-[24px] text-[#787878]">Years of Expertise</p>
                </div>
              </div>
            </ScrollTrigger>
          </div>
        </div>
      </div>
    </>
  );
}



// const Container = styled.div`
//   position: relative;
// `

// const Buttons = styled.div`
//   button{
//     width: 2rem;
//     height: 2rem;
//     background-color: rgba(255, 255, 255, 0.100);
//     cursor: pointer;
//     color: #01be96;
//     border: none;
//     position: absolute;
//     top: 45%;
//     right: -1rem;
//   }

//   .back{
//     left: -1rem;
//   }
// `