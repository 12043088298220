import React from 'react'

export default function Solution02() {
  
  return (
    <>
      <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Smart Parking System</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#FFF] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Smart Parking System
            </h2>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
            Each parking space is equipped with a battery powered occupancy sensor that can detect the absence, arrival, presence, and departure of a vehicle. The sensors are self – provisioning with a unique MAC address assigned to each unit that is associated with its serial number and bar code.
            </p>
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">The units can run up to 10 years and require no external wiring to install. When an occupancy sensor detects vehicle activity .Its LoRa network or a node in a LoRaWAN service provider.</p>
            <br/>
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">The gateway forward the packets to SSTPL Parking Cloud Service application that can reside on server. This turnkey parking management solution uses the sensors messages to keep track of open and occupied spaces Wireless sensors detect and report parking space occupancy, thus enabling active parking lot management features, such as search, navigation, and reservation. The easy retrofit solution for parking lots is installed in minutes. It was designed for detecting with high reliability if a parking space is occupied or available.</p>
          
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/smart_parking.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>
    </div> 
    </>
  )
}
