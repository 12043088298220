import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

export default function Careers() {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState('');

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_72cyggs';
    const templateId = 'template_a2n8d1t';
    const publickey = 'Q-I-kAd8O1djISe1s';

    const templateParams = {
      from_name: name,
      name: name,
      email: email,
      phone: phone,
      to_name: 'Neonax',
      message: message,
      file: selectedFile,
    };

    emailjs.send(serviceId, templateId, templateParams, publickey)
      .then((response) => {
        console.log('Email sent succesfully', response);
        alert('Email sent succesfully', response);
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setSelectedFile('');
      })
      .catch((error) => {
        console.error('Error sending email:', error)
      });

  }


  return (
    <>
      <div className='bg-white w-full h-auto pb-10 lg:pt-24 '>
        <div className="relative max-w-full h-[477px] bg-[#FFF] overflow-x-hidden"  >
          <img src="./assets/Careerban.png" alt="" className={`w-full h-full object-fill transition-transform duration-500 transform `} />
          <div className={`absolute top-32 w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)]  transition-transform duration-500 transform  `}>
            <h1 className='mx-5 md:mx-0 text-[24px] md:text-[48px] text-white'>Career in Neonax</h1>

            <p className='mx-5 md:mx-0 text-[14px] lg:text-[18px] font-normal text-justify text-white  leading-8'>Join our team to work hard, make a difference and succeed in a fast-paced environment and build powerfull connection with the rising talents</p>
          </div>
        </div>
        
        <div className='mt-12 mx-5 lg:mx-0 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1020px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]'>
          <h1 className='text-[32px] text-[#444444]'>Send your profile</h1>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', }}>
            <form className='grid grid-cols-1' method="POST" onSubmit={handleSubmit} >
              <input
                type="text" placeholder='Enter your name'
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full lg:w-[480px] text-[#444444] xl:w-[590px] h-[54px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-[10px] indent-2 mt-[48px]"
                required
              />

              <input
                type="number" placeholder='Mobile Number'
                className="w-full lg:w-[480px] text-[#444444] xl:w-[590px] h-[54px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-[10px] indent-2 mt-[30px]"
                name="phone"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />

              <input
                type="email" placeholder='Email address'
                className="w-full lg:w-[480px] text-[#444444] xl:w-[590px] h-[54px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-[10px] mt-[30px] indent-2"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className='flex mt-[30px]'>
                <input
                  type="text" placeholder='Upload your CV'
                  name="selectedFile"
                  value={selectedFile ? selectedFile.name : ''}
                  readOnly
                  required
                  onChange={(e) => setSelectedFile(e.target.value)}
                  className="w-full lg:w-[340px] classes.input text-[#444444] xl:w-[407px] h-[54px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-l-[10px]  indent-2"
                />
                <input
                  type="file" placeholder='Upload your CV'
                  ref={fileInputRef}
                  name="selectedFile"
                  onChange={handleFileChange}
                  className="w-full lg:w-[340px] hidden classes.input text-[#444444] xl:w-[407px] h-[54px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-l-[10px]  indent-2"
                  required
                />

              <button         onClick={handleBrowseClick}


                  className="bg-cyan-600 hover:cyan-700 classes.button text-white text-base w-[183px] lg:w-[140px] xl:w-[183px] h-[54px] rounded-r-[10px]"

                >
                  Browse
                 
                </button>

                
      
              </label>

              <textarea placeholder='Message' name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full lg:w-[480px] text-[#444444] xl:w-[590px] h-[218px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-[10px] mt-[30px] indent-3 placeholder:pt-2 placeholder:indent-5"
                required
              />

              <button
                className="bg-cyan-600 hover:cyan-700 text-white text-base mt-[30px] w-full lg:w-[480px] xl:w-[590px] h-[54px] rounded-[10px]"
              >
                Upload
              </button>
            </form>
          </div>

        </div>
      </div>
    </>
  )
}
