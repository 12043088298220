import React from 'react'
export default function Solution09() {
 
  return (
    <>
       <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Smart Perimeter Security System</h1>
            </div>
        </div>
              <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                  <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
                      <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
                          <h2 className="text-[24px] text-[#444444] font-medium mt-5">
                              Smart Perimeter
                          </h2>
                          <br />
                          <p className="font-normal pl-5 space-y-3 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8">
                              "Smart Perimeter Security Systems: Empowering Safety through Innovation
                              Experience the next level of safety with our Smart Perimeter Security Systems, redefining protection and surveillance. Leveraging cutting-edge technology, these systems introduce a new era of intelligent defense mechanisms. Monitor, analyze, and respond proactively to potential threats with real-time insights and automated safeguards. Discover how our Smart Perimeter Security Systems revolutionize security standards, ensuring comprehensive protection for your premises. Embrace innovation and fortify your defenses for a safer tomorrow."
                          </p>
                      </div>
                      <div className='mt-5 lg:mb-5'>
                          <h2 className="text-[24px] text-[#444444] font-medium mt-5">
                              Key features 
                          </h2>
                          <br />
                          <p className="font-normal pl-5 space-y-3 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8">
                              ●	Real-time Monitoring: Constant surveillance and monitoring of the perimeter, providing instant awareness of any security breaches or anomalies. <br />
                              ●	Intelligent Threat Detection: Advanced sensors and algorithms for the detection of unauthorized access, intrusions, or suspicious activities. <br />
                              ●	Automated Alerts and Response: Immediate alerts and automated responses to potential threats, ensuring swift action in critical situations. <br />
                              ●	Integration with Smart Devices: Seamless integration with smart devices for remote monitoring and control, offering flexibility and convenience. <br />
                              ●	Scalable and Customizable: Scalable solutions adaptable to various environments, customizable to specific security needs and requirements. <br />
                              ●	Analytics and Reporting: Robust analytics capabilities to analyze security data, providing insights and comprehensive reporting for informed decision-making. <br />
                              ●	Reliability and Durability: Reliable and durable systems designed to withstand environmental factors and ensure continuous operation.

                          </p>
                      </div>
                  </div>
              </div>

        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Fence Sensor Kits
            </h2>
            <br />
                          <ul className="font-normal list-disc pl-5 space-y-3 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8">
                <li>Plug & Play installation,Anyone can install the system without prior experience.</li>
                <li>Economical and affordable technology to cover 100% of the perimeter.</li>
                <li>No training or special tools required, comes with installation manual.</li>
                <li>No sophisticated calibration, zone sensitivity easily adjusted with dip switches.</li>
                <li>Protects fences up 3m high with a single cable</li>
                <li>No maintenance needed</li>
                <li>2x Dry contact relay alarm outputs (No or NC)</li>
                <li>Can be connected to any alarm system/DVR/access control/SCADA</li>
            </ul>
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/smart_peri1.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px]  transition-transform duration-500 transform `} />
            </div>
        </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/smart_peri2.jpg" alt="Img" className={` h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
            
            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5' > 
                          <ul className={`font-normal list-disc pl-5 space-y-1 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Buried & Fiber-Optic Soutions
            </h2>
            <br />
                <li>4 or 8 Sensors + Analyzer Per System.</li>
                <li>Circular Detection Pattern.</li>
                <li>Completely Invisible System.</li>
                <li>Sensitivity Adjustment Per Sensor.</li>
                <li>Al Based Detection Algorithm.</li>
                <li>Event Classification - Steps, Vehicle.</li>
                <li>Plug & Play Installation- No special tools needed.</li>
                <li>Standalone or IP Network Versions Play.</li>
            </ul>
            </div>
        </div>
    </div>
    <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
                          <ul className="font-normal list-disc pl-5 space-y-1 text-[14px] lg:text-[18px] text-justify text-[#555555]  leading-8">
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Buried & Fiber-Optic Soutions
            </h2>
            <br />
                <li>Up to 100km per processing unit.</li>
                <li>Sensing standard single mode fiber.</li>
                <li>Distributed Acoustic Sensing (DAS) Technology.</li>
                <li>Within 3m resolution over the entire perimeter.</li>
                <li>Signature based detection.</li>
                <li>Can be used on the fence and underground</li>
                <li>No electronics or power required on the field</li>
                <li>IP based Remote control - Software based zones</li>
            </ul>
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/smart_peri3.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px]  transition-transform duration-500 transform `} />
            </div>
        </div>
        </div>
    </div> 
    </>
  )
}