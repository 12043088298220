import React from 'react'

export default function Product10() {


    return (
        <>
            <div className='bg-white w-full pb-10 overflow-x-hidden'>
                <div className="relative max-w-full lg:pt-28">
                    <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
                    <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                        <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Cloud and  Application development</h1>
                    </div>
                </div>
                <div className="relative max-w-full h-auto bg-[#fff] py-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                    <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
                        <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-10">
                                We specialise in crafting custom mobile applications tailored to revolutionise your business. From concept to deployment, our team of experts ensures your vision translates into a seamless and intuitive mobile experience.
                            </p>
                            <br />
                            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
                                Mobile App Development
                            </h2>
                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                iOS App Development:
                            </h2>
                            
                            
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                               Elevate your brand presence on Apple devices with sleek, user-centric iOS applications crafted to engage and captivate your audience. 
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Android App Development:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Harness the power of the Android ecosystem with robust, scalable applications designed for optimal performance across a diverse range of devices.
                            </p>

                        </div>
                        <div className='mt-5 lg:mt-20'>
                            <img src="./assets/cloud-application-development.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[550px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform object-contain `} />
                        </div>
                    </div>
                </div>

                <div className="relative max-w-full h-auto bg-[#fff] py-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                    <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'  >
                        <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Cross-platform Development:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Maximise your reach and efficiency with versatile cross-platform apps that maintain consistency while catering to multiple operating systems.
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                UI/UX Design:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Engage users with visually stunning, intuitive interfaces that prioritize usability and deliver an unparalleled user experience.
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                App Maintenance & Support:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Ensure your app remains at peak performance with our comprehensive maintenance and support services, including updates and troubleshooting.
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                App Testing & QA:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Guarantee flawless functionality and reliability through rigorous testing and quality assurance procedures.
                            </p>
                        </div>
                        <div className='mt-5 lg:mt-5'>
                            <img src="./assets/5757453-scaled.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[550px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform object-contain `} />
                        </div>
                    </div>
                </div>


                <div className="relative max-w-full h-auto bg-[#fff] py-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                    <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
                        <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
                            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
                                Cloud App Development 
                            </h2>
                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Custom Cloud Solutions:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Tailored cloud applications designed to address your specific business needs, providing flexibility, scalability, and enhanced collaboration.
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                SaaS Development:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Transform your ideas into Software-as-a-Service (SaaS) applications, offering on-demand access to services while reducing infrastructure costs.
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Cloud Migration & Integration:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Seamlessly transition your operations to the cloud and integrate existing systems for optimized performance and data accessibility.
                            </p>

                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Cloud Consulting & Strategy:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Collaborate with our experts to formulate a comprehensive cloud strategy aligned with your business objectives and growth plans.
                            </p>


                            <h2 className="text-[20px] text-[#444444] font-medium mt-5">
                                Continuous Support & Optimization:
                            </h2>
                            <p className="font-normal text-base leading-6 text-justify text-[#444444] mt-2">
                                Ensure your cloud applications operate at peak efficiency with ongoing support, updates, and optimization services.
                            </p>
                        </div>
                        <div className='mt-5 lg:mt-5'>
                            <img src="./assets/cloud-computing-app.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[550px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform object-contain `} />
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}
