import React from 'react'

export default function Product02() {

  
  return (
    <>
           <div className='bg-white w-full pb-[5px] '>
        <div className="relative max-w-full  lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
            <h1 className='mx-5 md:mx-0 text-[24px] text-white'>BLE Based temperature and <br /> humidity sensor</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#FFF]  mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Smart BLE-based Simplicity and Accuracy
            </h2>
              <h3 className="text-[20px] text-[#444444] font-medium mt-2">
                Better Way to Manage Temperature & More
              </h3>
            <br />
              <h3 className="text-[24px] text-[#444444] font-medium">
                Co-effective, end-to-end Solution
              </h3>
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                SmartX HUB software provides automated temperature and humidity monitoring and alerting, as well as all required logging and reporting data for regulatory compliance.
            </p>
            <br />
              <h3 className="text-[24px] text-[#444444] font-medium">
                Creating dashboards and reports
              </h3>
              <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
               The cloud platform offers a set of widgets that can compose dashboards, viewable on the web or on a mobile app. Graphics, maps, tablets and notifications. Everything is there!
              </p>
            </div>
            <div className='mt-5 lg:-mt-12 lg:ml-20 flex flex-row justify-center lg:justify-normal' >
              <img src="./assets/image (1)-1.png" alt="Img" className={`mr-100 h-[400px] md:w-[250px] xl:w-[300px] md:h-[400px] lg:mt-20 transition-transform duration-500 transform `} />
            </div>
        </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#FFF] mb-10 overflow-x-hidden lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]" >

          <img src="./assets/image.png" alt="" className={`w-full h-auto transition-transform duration-500 object-contain transform $`} />

        </div>

    </div> 
    </>
  )
}
