
import React, { useState } from "react";
import emailjs from '@emailjs/browser';

export default function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_72cyggs';
    const templateId = 'template_6j77fum';
    const publickey = 'Q-I-kAd8O1djISe1s';

    const templateParams = {
      from_name: name,
      name: name,
      email: email,
      phone: phone,
      to_name: 'Neonax',
      message: message,
    };

    emailjs.send(serviceId, templateId, templateParams, publickey)
      .then((response) => {
        console.log('Email sent succesfully', response);
        alert('Email sent succesfully', response);
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending email:', error)
      });

  }
  
  return (
    <>
      <div className='bg-white w-full h-auto pb-10 lg:pt-20'>
      <div className="relative max-w-full h-[477px] bg-[#F1F1F1] overflow-x-hidden" >
    <img src="./assets/contact.png" alt="" className={`w-full h-full object-fill  transition-transform duration-500 transform `} />
    <div className={`absolute top-36 w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)]  transition-transform duration-500 transform  `}>
        <h1 className='mx-5 md:mx-0 text-[24px] md:text-[34px] text-white'>We'd love to hear from You</h1>
            <p className='mx-5 md:mx-0 text-[14px] lg:text-[18px] font-normal text-justify text-white  leading-8l'>Whether you have a question about Feel our solutions, products or services or anything else, our team is ready to answer all your questions.</p>
    </div>
    </div>

    <div className="grid grid-cols-1 lg:grid-cols-2 mx-5 lg:mx-0 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1020px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        
        <div className="mt-12 w-full lg:w-[488px]">
          <h1 className='text-[32px] text-[#444444]'>Send us a message</h1>
          <p className='text-[20px] text-black mt-10'>Do you have an enquiry about  <br />
            our solutions, products or services?</p>
        <p className='text-[20px] text-black mt-10'>Feel free to get in touch with us or send us your inquiry.</p>
        <div className='mt-[30px] '>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.4656577656633!2d55.37492607530165!3d25.119943177760774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f650a2af6c8a7%3A0xc02ce9d302b324e!2sNeonax%20FZCO!5e0!3m2!1sen!2sin!4v1686165080741!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='w-full h-[368px]' title=''></iframe>
        </div>
        </div>

        <div className='mt-12'>
            <form method='POST' onSubmit={handleSubmit}>
                  <input
                type="text" placeholder='Enter your name'
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                    className="w-full lg:w-[480px] text-[#444444] xl:w-[590px] h-[54px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-[10px] indent-2 mt-[48px]"
                  />

                  <input
                type="number" placeholder='Mobile Number'
                name="phone"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                    className="w-full lg:w-[480px] text-[#444444] xl:w-[590px] h-[54px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-[10px] indent-2 mt-[30px]"
                   
                  />

                  <input
                type="email" placeholder='Email address'
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                    className="w-full lg:w-[480px] text-[#444444] xl:w-[590px] h-[54px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-[10px] mt-[30px] indent-2"
                    
                  />

              <textarea placeholder='Message' name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                    className="w-full lg:w-[480px] text-[#444444] xl:w-[590px] h-[218px] bg-[#f1f1f1] border border-[#f1f1f1] rounded-[10px] mt-[30px] indent-3"
                    required
                  />

                <button type='submit'
                  className="bg-cyan-600 hover:cyan-700 text-white text-base mt-[30px] w-full lg:w-[480px] xl:w-[590px] h-[54px] rounded-[10px]"
                >
                  Send
                </button>
        </form> 
      </div>
</div>
</div>
    </>
  )
}
