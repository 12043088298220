import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link, useNavigate } from 'react-router-dom';



export default function Footer() {

    const navigate = useNavigate();
    const navigateToTeam = () => {
        navigate("/aboutus");
        setTimeout(() => {
            const teamSection = document.getElementById("team");
            if (teamSection) {
                const scrollOptions = {
                    behavior: "smooth",
                };
                const offset = -90;
                window.scrollTo({
                    top: teamSection.offsetTop + offset,
                    ...scrollOptions,
                });
            }
        }, 100);
    }

    const navigateToSolution = () => {
        navigate("/");
        setTimeout(() => {
            const solutionSection = document.getElementById("solution");
            if (solutionSection) {
                const scrollOptions = {
                    behavior: "smooth",
                };
                const offset = -90;
                window.scrollTo({
                    top: solutionSection.offsetTop + offset,
                    ...scrollOptions,
                });
            }
        }, 100);
    }

    const navigateToWhyus = () => {
        navigate("/aboutus");
        setTimeout(() => {
            const whyusSection = document.getElementById("whyus");
            if (whyusSection) {
                const scrollOptions = {
                    behavior: "smooth",
                };
                const offset = -90;
                window.scrollTo({
                    top: whyusSection.offsetTop + offset,
                    ...scrollOptions,
                });
            }
        }, 100);
    }

    return (
        <>
            <div className='w-full max-h-auto pt-10 bg-[#F1F1F1] lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]'>

                <div className='pl-5 md:mx-0 w-full h-auto grid grid-cols-1 md:grid-cols-4'>
                    <div className='mt-[59px]'>

                        <ul className='text-[16px] text-[#444444]'>
                            <h1 className='text-[20px] text-[#444444] font-medium'>OUR COMPANY</h1>
                            <Link to='/aboutus'><li className='mt-[20px]'><a href="/aboutus">About Neonax </a></li> </Link>
                            <li className='mt-4 cursor-pointer' onClick={navigateToTeam}>Our Team</li>
                            {/* <li className='mt-4'>Our Value</li> */}
                            <li className='mt-4 cursor-pointer' onClick={navigateToSolution}>Our Solution</li>
                            <li className='mt-4 cursor-pointer' onClick={navigateToWhyus}>WHY Us</li>
                            <Link to='/Careers'><li className='mt-4'>Careers</li></Link>
                            <Link to='/privacy_policy'><li className='mt-4'>Privacy Policy</li></Link>
                        </ul>
                    </div>
                    <div className='mt-[59px]'>

                        <ul className='text-[16px] text-[#444444] '>
                            <h1 className='text-[20px] text-[#444444] font-medium'>PRODUCTS</h1>
                            <Link to='/product01'><li className='mt-[20px]'>DCU</li></Link>
                            <Link to='/product02'><li className='mt-4'>BLE Based temperature</li></Link>
                            <Link to='/product03'><li className='mt-4'>6LOWPAN RF Mesh</li></Link>
                            <Link to='/product04'><li className='mt-4'>Bluetooth / Wifi based IoT App</li></Link>
                            <Link to='/product05'><li className='mt-4'>RFID Handheld scanners</li></Link>
                            <Link to='/product06'><li className='mt-4'>LoRaWAN</li></Link>
                            <Link to='/product07'><li className='mt-4'>EV Charging</li></Link>
                            <Link to='/product09'><li className='mt-4'>BLE Beacons</li></Link>
                        </ul>
                    </div>
                    <div className='mt-[59px]'>

                        <ul className='text-[16px] text-[#444444]'>
                            <h1 className='text-[20px] text-[#444444] font-medium'>SOLUTION</h1>
                            <Link to='/Solution01'> <li className='mt-[20px]'>Smart Tracking</li></Link>
                            <Link to='/Solution02'> <li className='mt-4'>Smart Parking System</li></Link>
                            <Link to='/Solution03'> <li className='mt-4'>Smart Electricity Meter</li></Link>
                            <Link to='/Solution04'> <li className='mt-4'>Smart Gas & Water Meter</li></Link>
                            <Link to='/Solution05'><li className='mt-4'>Smart Environmental Sensor</li></Link>
                            <Link to='/Solution06'><li className='mt-5'>Smart Street Light</li></Link>
                            <Link to='/Solution07'><li className='mt-4'>Smart Trash Bin</li></Link>
                            <Link to='/Solution08'><li className='mt-4'>Software Solution</li></Link>
                            <Link to='/Solution09'><li className='mt-4'>Smart Perimeter Security System</li></Link>
                        </ul>
                    </div>

                    <div className='mt-[31px]'>
                        <img src='./assets/logo.png' alt='' className='w-[170px] h-[56px]' />
                        <p className='text-[16px] text-[#444444] mt-[20px]'>DSO-DDP-A5-D-FXD-1060 <br /> Dubai Digital Park - Office A5- Dtec <br /> Silicon Oasis, Dubai - UAE <br />
                            Tel: +971564323436 <br />
                            Email: <span className='text-[#008FC9]'>enquiry@neonax.com</span></p>

                        <div>
                            <h1 className='text-black font-medium mt-2'>Follow us</h1>
                            <div className="foot_img">
                                <a href="https://www.facebook.com/people/Neona-X-Fzco/pfbid036s3J5n7CtfaZ1sHCvypeTCjh6x4RczAxD2ygQENR4Y1aBe6LDPBF7GWRQYXTpkKal/" target="_blank"><FacebookIcon className='icon text-[#008FC9] !text-[42px] !rounded-md' /></a>
                                <a href="https://www.linkedin.com/company/neonax-fzco/" target="_blank"><LinkedInIcon className='icon text-[#008FC9] !text-[42px] rounded-full' /></a>
                                <a href="https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Ffeed%2F&trk=login_reg_redirect" target="_blank"><aedInIcon className='icon text-[#008FC9] !text-[42px] rounded-full' /></a>
                                <a href="https://www.instagram.com/neona_x_fzco/?utm_medium=copy_link" target="_blank"><InstagramIcon className='icon text-[#008FC9] !text-[42px] rounded-full' /></a>
                                <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fhome" target="_blank"><TwitterIcon className='icon text-[#008FC9] !text-[42px] rounded-full' /></a>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='border border-[#444444] mt-5 mx-5 md:mx-0'></div>

                <div className='mx-5 md:mx-0 mt-5 text-center justify-center pb-10'>
                    <p className='text-[16px] text-[#444444]'>Neonax- FZCO - © 2020 | All Rights Reserved - Designed & Developed by <a href='https://meerana.ae/' target="_blank">Meerana Technologies LLP</a></p>
                </div>

            </div>
        </>
    )
}
