import React from 'react'

export default function Solution11() {

    

    return (
        <>
            <div className='bg-white w-full pb-10 overflow-x-hidden'>
                <div className="relative max-w-full lg:pt-28">
                    <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
                    <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                        <h1 className='mx-5 md:mx-0 text-[24px] text-white'>AI solutions and AI based IoT computing</h1>
                    </div>
                </div>
                <div className="relative max-w-full h-auto bg-[#fff] py-5 mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
                    <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'  >
                        <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px] transition-transform duration-500 transform `}>
                            <h2 className="text-[24px] text-[#444444] font-medium mb-2">
                                AI solutions and AI based IoT computing
                            </h2>
                            <br />
                            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                                Unlock the potential of Artificial Intelligence (AI) solutions that revolutionize data analysis, decision-making, and process optimization. Our AI-based IoT computing seamlessly merges AI capabilities with Internet of Things (IoT) devices, enabling real-time data processing and smart, autonomous actions. Discover how this integration propels efficiency, innovation, and transformative solutions across industries. Experience the future of intelligent technologies driving unparalleled advancements.
                            </p>
                            <br />
                            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
                                Features:
                            </h2>
                            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                                ●	Process Optimization: Optimize workflows and tasks through AI-driven automation, improving efficiency and resource allocation. <br />
                                ●	Real-time Decision-making: AI-based IoT computing enables real-time data processing, facilitating smart, autonomous actions for swift responses. <br />
                                ●	Interconnected Intelligence: Integration of AI with IoT devices creates a network of interconnected intelligence, enabling seamless communication and collaboration between devices. <br />
                                ●	Predictive Capabilities: AI solutions leverage machine learning algorithms to predict patterns and trends, empowering proactive decision-making. <br />
                                ●	Efficiency Enhancement: Enhance operations by leveraging AI's ability to learn, adapt, and continuously improve processes.
<br />
                            </p>
                        </div>
                        <div className='mt-5 lg:mt-36'>
                            <img src="./assets/AIOT.png" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[550px] md:h-[350px] py-2 lg:ml-20 transition-transform duration-500 transform object-contain `} />
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}
