import React from 'react'

export default function Solution06() {
  

  return (
    <>
       <div className='bg-white w-full pb-[5px]'>
        <div className="relative max-w-full lg:pt-28">
            <img src="./assets/backgrnd.jpg" alt="" className='w-full h-[158px] object-cover' />
            <div className='absolute top-10 md:top-14 lg:top-[170px] w-[280px] md:w-[500px] lg:ml-[calc((100%-1000px)/2)]  xl:ml-[calc((100%-1200px)/2)] '>
                <h1 className='mx-5 md:mx-0 text-[24px] text-white'>Smart Street Light</h1>
            </div>
        </div>
        <div className="relative max-w-full h-auto bg-[#fff] mt-10 mb-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
        <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0' >
            <div className={`p-2 lg:p-0 lg:w-[460px] xl:w-[570px]  transition-transform duration-500 transform `}>
            <h2 className="text-[24px] text-[#444444] font-medium mt-5">
            Smart Street Light
            </h2>   
            <br />
            <p className="text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8">
                A cutting-edge solution for managing street lights from a distance, our smart street light system offers remote monitoring and control for clusters of street lights. This cloud-based system operates on a group-oriented basis, enabling efficient oversight. <br /> <br />

                Through cloud-powered remote monitoring, we facilitate in-depth analytics and reporting of street light usage. Our Neona Data Concentrator Unit serves as the link between street lights and the cloud server, relaying essential data such as power outages and faults. <br /> <br />

                In essence, our system revolutionizes street light management, ensuring optimal performance, energy efficiency, and prompt responses through cloud-based supervision

            </p>
            </div>
            <div className='mt-5 lg:mt-0'>
            <img src="./assets/smart_street_light.jpg" alt="Img" className={`mr-100 h-[400px] md:w-full lg:w-[460px] xl:w-[590px] md:h-[516px] transition-transform duration-500 transform `} />
            </div>
        </div>
    </div>

        <div className="relative max-w-full h-auto bg-[#fff] mt-10 lg:pl-[calc((100%-1000px)/2)] lg:pr-[calc((100%-1000px)/2)] xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)]">
          <div className='grid grid-cols-1 lg:grid-cols-2 mx-5 md:mx-0'>
            <div className='mt-5 lg:mt-0'>
              <img src="./assets/streetlight.png" alt="Img" className={` h-[400px] md:w-full xl:w-full md:h-[350px] object-contain py-2 transition-transform duration-500 transform `} />
            </div>

            <div className='p-2 lg:p-0 lg:w-[460px] xl:w-[570px] lg:ml-5'  >
              <p className={`text-[14px] lg:text-[18px] font-normal text-justify text-[#555555]  leading-8 mt-10 transition-transform duration-200 transform `}>
                <h2 className="text-[24px] text-[#444444] font-medium mt-10">
                  Features :
                </h2>
                <br />
                • Remote monitoring and controlling group of street lights. <br />
                • Cloud-based remote monitoring performs analytics and reporting of street light consumption through Neona Data Concentrator Unit. <br />
                • Wired and Wireless solution based on environmental condition <br /> • Provide power failure, fault occurrence information to the cloud server. <br /> • Solution using Android and web application <br />
                • Ensures no energy is wasted <br />
                •Optimum utilisation of street lighting in a city over PLCC/RF  communication

              </p>
            </div>
          </div>
        </div>
    </div> 
    </>
  )
}
